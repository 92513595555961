import moment from 'moment'
import getConfig from 'next/config'
import { isNull, sortBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
// import CreateIcon from '@material-ui/icons/Create'
// import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'

import { ExecutionAPI, LintasGeneralAPI } from '../../api'
import { Colors } from '../../styles'
import { ConvertLocalTimeGet, GenerateFilterTerm } from '../../utils'
import {
  Alerts,
  CodeCell,
  Loading,
  MasterGrid,
  ColumnOption,
  ModalConfirmation
} from '../../components'

import GridColumn from './column.json'
import ExecutionModal from './modal/execution.modal.container'
import {
  Circular,
  StatusCell,
  // InboundButton,
  ActionContainer,
  LoadingContainer
} from './execution.styled'
import { SET_SERVICE_REQUEST_ALL_DETAIL } from '../../redux/actions'

const ExecutionTabular = (props) => {
  const dispatch = useDispatch()
  const appSize = useSelector((state) => state.appReducer)
  const { publicRuntimeConfig } = getConfig()
  // const RUN_OUTSCOPE_FUNCTION = publicRuntimeConfig.RUN_OUTSCOPE_FUNCTION === 'true'

  const [firstLoad, setFirstLoad] = useState(true)
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    filter: [],
    filterString: '[]'
  })
  const [dataCount, setDataCount] = useState(0)
  const [dataGrid, setDataGrid] = useState([])
  const [dataAll, setDataAll] = useState({})
  const [listVessel, setListVessel] = useState([])
  const [dataSrCode, setDataSrCode] = useState('')
  const [show, setShow] = useState(false)
  const [activeColumns, setActiveColumns] = useState([])
  const [inactiveColumns, setInactiveColumns] = useState([])
  const [gridColumnActive, setGridColumnActive] = useState([])
  const [codeVessel, setCodeVessel] = useState('')
  const [gridColumnLoading, setGridColumnLoading] = useState(true)
  // const [inboundData, setInboundData] = useState([])
  const [showAlert, setAlert] = useState({
    visible: false,
    msg: '',
    success: false,
    type: ''
  })
  const heightGrid = appSize.height - 190 > 455 ? appSize.height - 190 : 455
  const { Location } = useSelector((state) => state.usersProfileReducer)
  const [pending, setPending] = useState(false)

  const _calculateDateGrid = (minutes) => {
    const min = moment.duration(minutes, 'minutes')
    const hh = parseInt(min.asHours())
    const hour = hh ? `${hh} ${hh > 1 ? 'hours' : 'hour'}` : '0 hour'
    const mm = parseInt(min.asMinutes()) % 60
    const minute = mm ? `${mm} ${mm > 1 ? 'minutes' : 'minute'}` : '0 minute'
    return hour || minute ? `${hour} ${minute}` : '0 hours 0 minute'
  }

  const onPageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take
    })

    fetchData({
      skip: event.page.skip,
      take: event.page.take,
      filterString: dataState.filterString
    })
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchData({
        ...dataState,
        filterString: GenerateFilterTerm(dataState.filter, GridColumn)
      })
      setFirstLoad(false)
    }, publicRuntimeConfig.FILTER_TIMEOUT)
    return () => clearTimeout(delay)
  }, [dataState.filter])

  const onDataStateChange = ({ data }) => {
    GenerateFilterTerm(data.filter, GridColumn)

    setDataState({
      ...data,
      filter: data.filter,
      filterString: GenerateFilterTerm(data.filter, GridColumn)
    })
  }

  const fetchData = async ({ skip, take, filterString }) => {
    let filter = filterString
    const filterLoc = `{"type":"number","value":"${Location.LocationID}","field":"location_code"}`
    const { LocationDescription } = Location
    if (filterString === '[]') {
      filter = `[${filterLoc}]`
    } else {
      filter = filter.replace('"}]', `"},${filterLoc}]`)
    }
    try {
      setPending(true)
      const response = await ExecutionAPI.getAllExecutions(
        skip,
        take,
        LocationDescription,
        filter
      )
      const resp = await ExecutionAPI.GetColumnOption()
      if (resp.status === 200) {
        const dataColumns = resp.data.data
        const active = []
        const inactive = []
        let column = {}

        dataColumns.forEach((item) => {
          column = {
            id: item.Id,
            code: item.Code,
            columnName:
              item.Column_Name == 'contract_no'
                ? 'agreement_no'
                : item.Column_Name,
            columnDesc: item.Column_Description,
            sortNo: item.Sort_No,
            isActive: item.Is_Active
          }
          if (column.isActive) active.push(column)
          else inactive.push(column)
        })

        const gridSelected = GridColumn.filter((element) => {
          const activeElement = active.find(
            (ele) =>
              ele.columnName.toLowerCase() === element.field.toLowerCase()
          )
          if (element.field.toLowerCase() === 'action' || activeElement) {
            if (activeElement) {
              element['sortNo'] = activeElement.sortNo
              element['columnName'] = activeElement.columnName
              element['columnDesc'] = activeElement.columnDesc
              element['code'] = activeElement.code
              element['id'] = activeElement.id
            } else {
              element['sortNo'] = -1
            }

            return element
          } else return false
        })
        const sorted = [...gridSelected.filter((e) => e !== false)].sort(
          (a, b) => a.sortNo - b.sortNo
        )
        setActiveColumns(
          sorted.filter((e) => e.field.toLowerCase() !== 'action')
        )
        setGridColumnActive(sorted)
        setGridColumnLoading(false)
        setInactiveColumns(inactive)

        setDataGrid(
          isNull(response.data.data)
            ? []
            : response.data.data.map((e) => {
                let Plan_Arrival_No_Format = e.Plan_Arrival
                let Plan_Depart_No_Format = e.Plan_Depart
                e['Forecast_Berth_Dur_format'] = _calculateDateGrid(
                  e['Forecast_Berth_Dur']
                )
                e['Estimate_Berth_Dur_format'] = _calculateDateGrid(
                  e['Estimate_Berth_Dur']
                )
                e['Actual_Berth_Dur_format'] = _calculateDateGrid(
                  e['Actual_Berth_Dur']
                )
                e['Plan_Berth_Dur_format'] = _calculateDateGrid(
                  e['Plan_Berth_Dur']
                )
                e['Created_Date'] =
                  moment(e.Created_Date).format('MM/DD/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Created_Date,
                        'MM/DD/YYYY HH:mm'
                      )
                e['Atd'] =
                  moment(e.Atd).format('MM/DD/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Atd, 'MM/DD/YYYY HH:mm')
                e['Ata'] =
                  moment(e.Ata).format('MM/DD/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Ata, 'MM/DD/YYYY HH:mm')
                e['Etd'] =
                  moment(e.Etd).format('MM/DD/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Etd, 'MM/DD/YYYY HH:mm')
                e['Eta'] =
                  moment(e.Eta).format('MM/DD/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Eta, 'MM/DD/YYYY HH:mm')
                e['Plan_Arrival'] =
                  moment(e.Plan_Arrival).format('MM/DD/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Plan_Arrival,
                        'DD/MM/YYYY HH:mm'
                      )
                e['No_Later_Than'] =
                  moment(e.No_Later_Than).format('MM/DD/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.No_Later_Than,
                        'MM/DD/YYYY HH:mm'
                      )
                e['Plan_Depart'] =
                  moment(e.Plan_Depart).format('MM/DD/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Plan_Depart,
                        'DD/MM/YYYY HH:mm'
                      )
                e['Forecast_Arrival'] =
                  moment(e.Forecast_Arrival).format('MM/DD/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Forecast_Arrival,
                        'MM/DD/YYYY HH:mm'
                      )
                e['Forecast_Depart'] =
                  moment(e.Forecast_Depart).format('MM/DD/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Forecast_Depart,
                        'MM/DD/YYYY HH:mm'
                      )
                e['Modified_Date'] =
                  moment(e.Modified_Date).format('MM/DD/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Modified_Date,
                        'MM/DD/YYYY HH:mm'
                      )
                e['No_Later_Than'] =
                  moment(e.No_Later_Than).format('MM/DD/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.No_Later_Than,
                        'MM/DD/YYYY HH:mm'
                      )
                e['Postponed_Date'] =
                  moment(e.Postponed_Date).format('MM/DD/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Postponed_Date,
                        'MM/DD/YYYY HH:mm'
                      )
                e['Acivated_Date'] =
                  moment(e.Acivated_Date).format('MM/DD/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Acivated_Date,
                        'MM/DD/YYYY HH:mm'
                      )
                e['Eta_Non_Format'] = e['Eta']
                e['Etd_Non_Format'] = e['Etd']
                e['Plan_Arrival_Non_Format'] = ConvertLocalTimeGet(
                  e.Location,
                  Plan_Arrival_No_Format,
                  'MM/DD/YYYY HH:mm'
                )
                e['Plan_Depart_Non_Format'] = ConvertLocalTimeGet(
                  e.Location,
                  Plan_Depart_No_Format,
                  'MM/DD/YYYY HH:mm'
                )
                e['Forecast_Arrival_Non_Format'] = e['Forecast_Arrival']
                e['Forecast_Depart_Non_Format'] = e['Forecast_Depart']
                e['Ata_Non_Format'] = e['Ata']
                e['Atd_Non_Format'] = e['Atd']
                e['Created_Date_Non_Format'] = e['Created_Date']
                e['Modified_Date_Non_Format'] = e['Modified_Date']

                return e
              })
        )
        setDataCount(response.data.count)
      }
      setPending(false)
    } catch (error) {
      setPending(false)
      console.log(error)
    }
  }
  useEffect(() => {
    if (!firstLoad) {
      fetchData(dataState)
    }
  }, [show])

  const closeExeModal = () => {
    setDataAll({})
    setShow(false)
    props.getDataUnAllocated()
  }

  useEffect(() => {
    getListJetty()
  }, [show])

  const getListJetty = async () => {
    try {
      const result = await ExecutionAPI.getAllJetty(
        Location.LocationDescription
      )
      if (result.data && result.data.data) {
        setListVessel(result.data.data)
      }
    } catch (err) {
      console.log('eror get list jetty', err)
    }
  }

  // const inboundToPbms = async (code, srNo) => {
  //   try {
  //     const result = await ExecutionAPI.InboundToPBMS(code)
  //     if (!result.data.error) {
  //       setInboundData([
  //         ...inboundData,
  //         {
  //           Code: result.data.data.SR_Code,
  //           Status: result.data.data.Status,
  //           Remarks: '',
  //           Created_Date: '',
  //         },
  //       ])
  //       setAlert({
  //         msg: `Inbound to PBMS with SR No. ${srNo} is In Progress`,
  //         success: true,
  //         visible: true,
  //         type: 'insert',
  //       })
  //     } else {
  //       setAlert({
  //         msg: `Inbound to PBMS with SR No. ${srNo} is Failed`,
  //         success: false,
  //         visible: true,
  //         type: 'insert',
  //       })
  //     }
  //     return result
  //   } catch (err) {
  //     console.log('inbound to pbms err: ', err)
  //     return err
  //   }
  // }

  const getDataItemSR = async (dataSrCode) => {
    const { LocationDescription } = Location

    const response = await ExecutionAPI.getAllExecutions(
      0,
      1000,
      LocationDescription,
      `[{"type":"text", "field":"Code", "value":"${dataSrCode}"}]`
    )
    const e = response.data.data[0]
    setDataAll({
      ...e,
      Plan_Arrival:
        moment(e.Plan_Arrival).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(e.Location, e.Plan_Arrival, 'DD/MM/YYYY HH:mm'),
      Plan_Depart:
        moment(e.Plan_Depart).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(e.Location, e.Plan_Depart, 'DD/MM/YYYY HH:mm'),
      Plan_Depart_Non_Format:
        moment(e.Plan_Depart).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(e.Location, e.Plan_Depart, 'DD/MM/YYYY HH:mm'),
      Forecast_Arrival:
        moment(e.Forecast_Arrival).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(
              e.Location,
              e.Forecast_Arrival,
              'DD/MM/YYYY HH:mm'
            ),
      Plan_Arrival_Non_Format:
        moment(e.Plan_Arrival).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(e.Location, e.Plan_Arrival, 'DD/MM/YYYY HH:mm'),
      Forecast_Depart:
        moment(e.Forecast_Depart).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(
              e.Location,
              e.Forecast_Depart,
              'DD/MM/YYYY HH:mm'
            ),
      No_Later_Than:
        moment(e.No_Later_Than).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(
              e.Location,
              e.No_Later_Than,
              'DD/MM/YYYY HH:mm'
            ),
      Postponed_Date:
        moment(e.Postponed_Date).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(
              e.Location,
              e.Postponed_Date,
              'DD/MM/YYYY HH:mm'
            ),
      Acivated_Date:
        moment(e.Acivated_Date).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(e.Location, e.Acivated_Date, 'DD/MM/YYYY HH:mm')
    })
  }

  const reorderColumns = (event) => {
    let columns = [...event.columns].filter((item) => item.field !== 'action')
    columns = sortBy(columns, ['orderIndex']).map((item1, index) => {
      const found = activeColumns.find(
        (item2) => item2.columnName === item1.field.toLowerCase()
      )
      return { ...found, sortNo: index }
    })
    setActiveColumns(columns)
    try {
      ExecutionAPI.UpdateColumnOption(
        columns.map((item) => ({
          Id: item.id,
          Code: item.code,
          Column_Name: item.columnName,
          Column_Description: item.columnDesc,
          Is_Active: true,
          Sort_No: item.sortNo
        }))
      )
    } catch (error) {
      return error
    }
  }

  // const _actionCell = ({ colSpan, dataItem }) => {
  //   const found = inboundData.find((item) => item.Code === dataItem.Code)
  //   const inboundDisabled =
  //     dataItem.Contract_No === '' ||
  //     (dataItem.Inbound_MSR || '').toLowerCase() === 'in progress' ||
  //     found !== undefined

  //   return (
  //     <td
  //       style={{ position: 'sticky', left: '0px' }}
  //       className='k-grid-content-sticky'
  //       id='m_exct-tabular_action_btn'
  //       colSpan={colSpan}
  //     >
  //       <ActionContainer>
  //         <div
  //           id='m-exct-tabular-edit-btn'
  //           style={{
  //             cursor: 'pointer',
  //             flex: 1,
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //           }}
  //           onClick={() => {
  //             setDataAll({
  //               ...dataItem,
  //               Eta: dataItem.Eta_Non_Format,
  //               Etd: dataItem.Etd_Non_Format,
  //               Ata: dataItem.Ata_Non_Format,
  //               Atd: dataItem.Atd_Non_Format,
  //               Plan_Arrival: dataItem.Plan_Arrival_Non_Format,
  //               Plan_Depart: dataItem.Plan_Depart_Non_Format,
  //               Forecast_Arrival: dataItem.Forecast_Arrival_Non_Format,
  //               Forecast_Depart: dataItem.Forecast_Depart_Non_Format,
  //               Created_Date: dataItem.Created_Date_Non_Format,
  //               Modified_Date: dataItem.Modified_Date_Non_Format,
  //             })
  //             setDataSrCode(dataItem.Code)
  //             setShow(true)
  //             setCodeVessel(dataItem.Vessel_Code)
  //           }}
  //         >
  //           <CreateIcon htmlColor={Colors.green} style={{ opacity: 1 }} />
  //         </div>
  //         {(dataItem.Inbound_MSR || '').toLowerCase() !== 'succeed' && RUN_OUTSCOPE_FUNCTION && (
  //           <InboundButton
  //             id='m-exct-tabular-inbound-btn'
  //             style={{
  //               flex: 1,
  //               display: 'flex',
  //               justifyContent: 'center',
  //               alignItems: 'center',
  //             }}
  //             disabled={inboundDisabled}
  //             onClick={() => {
  //               if (!inboundDisabled || (dataItem.Inbound_MSR || '').toLowerCase() !== 'succeed') {
  //                 inboundToPbms(dataItem.Code, dataItem.Sr_No)
  //               }
  //             }}
  //           >
  //             <ExitToAppIcon
  //               htmlColor={dataItem.Contract_No && !inboundDisabled ? Colors.green : 'gray'}
  //             />
  //           </InboundButton>
  //         )}
  //       </ActionContainer>
  //     </td>
  //   )
  // }

  const _statusCell = ({ colSpan, dataItem }) => (
    <td>
      <ActionContainer
        id='exct-tabular_status_action_btn'
        onClick={() => {
          console.log('test status')
        }}
        colSpan={colSpan}
      >
        {dataItem.Sr_Status.length > 0 ? (
          <StatusCell
            backgroundColor={
              dataItem.Sr_Status.toLowerCase() === 'allocated'
                ? '#364fc7'
                : dataItem.Sr_Status.toLowerCase() === 'in progress'
                ? '#6DB200'
                : dataItem.Sr_Status.toLowerCase() === 'completed'
                ? Colors.aliceBlue
                : dataItem.Sr_Status.toLowerCase() === 'postponed'
                ? Colors.red
                : dataItem.Sr_Status.toLowerCase() === 'approved'
                ? '#2b8a3e'
                : ''
            }
            color={
              dataItem.Sr_Status.toLowerCase() === 'approved' ||
              dataItem.Sr_Status.toLowerCase() === 'in progress' ||
              dataItem.Sr_Status.toLowerCase() === 'completed' ||
              dataItem.Sr_Status.toLowerCase() === 'postponed' ||
              dataItem.Sr_Status.toLowerCase() === 'allocated'
                ? Colors.white
                : Colors.black
            }
          >
            {dataItem.Sr_Status}
          </StatusCell>
        ) : (
          <div />
        )}
      </ActionContainer>
    </td>
  )

  const _getDataContract = async (params) => {
    try {
      const response = await LintasGeneralAPI.GetDataContract(
        params?.Client_Code
      )
      if (response?.data?.message?.Contracts) {
        return response.data.message.Contracts
      }
    } catch (error) {
      return error
    }
  }

  const _clickCell = async(dataItem)=> {
    const contracts = await _getDataContract(dataItem.Client_Code)
      setDataAll({
        ...dataItem,
        Contracts: contracts,
        Plan_Arrival: dataItem.Plan_Arrival_Non_Format,
        Plan_Depart: dataItem.Plan_Depart_Non_Format,
        Eta: dataItem.Eta_Non_Format,
        Etd: dataItem.Etd_Non_Format,
        Ata: dataItem.Ata_Non_Format,
        Atd: dataItem.Atd_Non_Format,
        Created_Date: dataItem.Created_Date_Non_Format,
        Modified_Date: dataItem.Modified_Date_Non_Format
      })
      setDataSrCode(dataItem.Code)
      setShow(true)
      setCodeVessel(dataItem.Vessel_Code)
      dispatch({
        type: SET_SERVICE_REQUEST_ALL_DETAIL,
        payload: dataItem
      })
  }
  const _codeCell = ({ dataItem }) => {
    return (
      <CodeCell
        id={`m_exct-tabular_detail-${dataItem.Id}`}
        disableTooltip={false}
        style={{ cursor: 'pointer' }}
        // Note : Date already converted base on Data Grid
        onClick={() => _clickCell(dataItem)}
      >
        {dataItem.Sr_No}
      </CodeCell>
    )
  }

  const _percentCell = ({ dataItem }) => {
    return (
      <td>
        <div>
          <div style={{ margin: '5px 60px' }}>
            <Circular
              text={`${dataItem.Total_Progress_Data_Activity}%`}
              value={dataItem.Total_Progress_Data_Activity}
            />
          </div>
        </div>
      </td>
    )
  }

  const _grid = () =>
    !gridColumnLoading ? (
      <MasterGrid
        id='m_exct-tabular_grid'
        actionLocked
        height={heightGrid}
        data={dataGrid ? dataGrid : []}
        total={dataCount}
        codeCell={_codeCell}
        percentCell={_percentCell}
        dataState={dataState}
        gridColumn={gridColumnActive}
        // actionCell={_actionCell}
        customCell={_statusCell}
        pageSizes={[20, 50, 100]}
        onPageChange={onPageChange}
        onDataStateChange={onDataStateChange}
        reorderable={true}
        onColumnReorder={reorderColumns}
        pending={pending}
      />
    ) : (
      <LoadingContainer style={{ height: '75vh', alignItems: 'center' }}>
        <Loading id='sr-grid-loading-indicator' />
      </LoadingContainer>
    )

  return (
    <>
      {_grid()}
      <ExecutionModal
        show={show}
        dataAll={dataAll}
        dataSrCode={dataSrCode}
        listVessel={listVessel}
        codeVessel={codeVessel}
        isDetail={false}
        getDataExecution={() => getDataItemSR(dataSrCode)}
        closeExeModal={closeExeModal}
      />
      {props.showColumnOption && (
        <ColumnOption
          dataState={dataState}
          fetchData={fetchData}
          parentActiveColumns={activeColumns}
          parentInactiveColumns={inactiveColumns}
          setShowColumnOption={props.setShowColumnOption}
          setGridColumnLoading={setGridColumnLoading}
          updateApi={ExecutionAPI.UpdateColumnOption}
        />
      )}
      {showAlert.visible && showAlert.success && (
        <ModalConfirmation
          open={showAlert.visible}
          close={() => {
            setAlert({ ...showAlert, visible: false })
            // setInboundData([])
            setGridColumnLoading(true)
            fetchData(dataState)
          }}
          isWarn={true}
          title='ATTENTION !!'
          message={showAlert.msg}
          icon={
            <ErrorOutlineRoundedIcon
              style={{ fontSize: '60px', color: '#eed202' }}
            />
          }
        />
      )}
      {showAlert.visible && !showAlert.success && (
        <Alerts
          type={showAlert.type}
          msg={showAlert.msg}
          close={() => {
            setAlert({ ...showAlert, visible: false })
            // setInboundData([])
            setGridColumnLoading(true)
            fetchData(dataState)
          }}
          open={showAlert.visible}
          success={showAlert.success}
        />
      )}
    </>
  )
}

export default ExecutionTabular
