// eslint-disable-next-line no-unused-vars
/* eslint-disable react/jsx-key */
import _ from 'lodash'
import * as Yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Checkbox as CustomCheckbox, FormControlLabel } from '@material-ui/core'

import { Alerts, Input, ButtonDetail, Checkbox } from '../../components'
import {
  ExecutionAPI,
  SettlementAPI,
  ServiceRequestAPI,
  MasterDataAPI
  // LintasGeneralAPI
} from '../../api'
import {
  Row,
  Form,
  Text,
  TopIcon,
  TextTop1,
  TextTop2,
  TextTop3,
  SVGIcon,
  Container,
  TitleValue,
  ContainerTop,
  WrapperValue,
  TitleBerthing,
  FormContainer,
  LeftFormContainer,
  RightFormContainer
} from '../service_request/service-request.styled'
import { Images } from '../../assets'

import SRField from './column_modal_sr_mapping_field.json'
import { GetDurationMinutes, ConvertLocalTimeGet } from '../../utils'
import ModalWorkPermitCCU from './settlement.modal.work.permit-ccu.container'

import { BorderLine, RevisedBy } from './settlement.styled'
import DocGridCCU from './column.modal_ccu_detail.json'
import DocGridPermit from './column.modal_work_permit.json'

const ServiceRequestModalContent = (props) => {
  const { data, dataContract } = props
  const { dataSr } = useSelector((state) => state.serviceRequestDetailReducer)
  const formatMonthName = 'DD MMM YYYY HH:mm'
  const statusListShowTime = [
    'completed',
    'waiting for confirmation',
    'finalized'
  ]
  const showTime =
    data.Sr_Status && statusListShowTime.includes(data.Sr_Status.toLowerCase())
  const timerInbound = useRef(null)
  const [isFromManualTrigger, setIsFromManualTrigger] = useState(false)
  const [isFromManualTriggerDepart, setIsFromManualTriggerDepart] =
    useState(false)
  const [isFromManualTriggerArrival, setIsFromManualTriggerArrival] =
    useState(false)
  const [srMapping, setSRMapping] = useState([])

  // const [dataContract, setDataContract] = useState([])
  const [dataRecipient, setDataRecipient] = useState([])
  const [contractTitle, setContractTitle] = useState([])
  const [dataMasterPermit, setDataMasterPermit] = useState([])
  const [modalPermitCCU, setModalPermitCCU] = useState({
    modal_type: '',
    isOpen: false
  })

  const handleCloseModal = () =>
    setModalPermitCCU({ modal_type: '', status: false })
  const [showAlert, setAlert] = useState({
    visible: false,
    msg: '',
    success: false,
    type: ''
  })

  useEffect(() => {
    _getSRField()
    _getDataRecipient()
    _getContractTitle()
    // _getDataContract(data)
    _getDataWorkPermit()
  }, [props.data])

  const FormSubmit = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data
    }
  })

  const _getDataRecipient = async () => {
    try {
      const recipient = await MasterDataAPI.GetMasterRecipient()
      setDataRecipient(recipient.data.message.data ?? [])
    } catch (error) {
      console.log('eror', error)
    }
  }


  const _getContractTitle = async () => {
    try {
      const poList = await MasterDataAPI.GetContractTitle()
      setContractTitle(poList.data.message.POList ?? [])
    } catch (error) {
      console.log('eror', error)
    }
  }

  const _getDataWorkPermit = async () => {
    try {
      const response = await ServiceRequestAPI.GetMasterWorkPermit()
      setDataMasterPermit(response.data.data)
    } catch (error) {
      console.log('eror', error)
    }
  }

  const _getSRField = async () => {
    try {
      const data = {
        action: 'GET_SR_FIELD',
        data: {
          SR_Type_Code: props.data.Sr_Type_Alias,
          SR_Field_Header_Code: props.data.Sr_Field_Header_Code
        },
        query: {
          Skip: 0,
          Take: 100,
          orders: [
            {
              field: 'sort',
              dir: 'asc'
            }
          ],
          Filters: [{ field: 'is_active', type: 'bool', value: 'true' }]
        }
      }

      const result = await ExecutionAPI.GetSRFieldMapping(data)
      if (
        result?.message &&
        result?.message?.data &&
        result?.message?.data?.length > 0
      ) {
        const srField = result.message.data.map((dt) => {
          const port = SRField.filter(
            (sr) => sr.sr_render.toLowerCase() == dt.Data_Field.toLowerCase()
          )
          let field = {
            ...dt,
            Data_Field_Port: port.length > 0 ? port[0].sr_field : '',
            Disable: port.length > 0 ? port[0].sr_disable : false
          }
          return field
        })
        setSRMapping(srField.sort((a, b) => a.Sort - b.Sort))
      }
    } catch (err) {
      console.log('eror get sr mapping', err)
    }
  }

  const SettlementSRForm = useFormik({
    initialValues: {
      Billing_Berth_Dur_Detail: data.Billing_Berth_Dur_Detail
        ? data.Billing_Berth_Dur_Detail
        : '0',
      Billing_Time_Arrival: data.Billing_Time_Arrival
        ? data.Billing_Time_Arrival
        : '',
      Billing_Time_Depart: data.Billing_Time_Depart
        ? data.Billing_Time_Depart
        : ''
    },
    validationSchema: Yup.object({
      Billing_Berth_Dur_Detail: Yup.number().required(
        'Billing Berth Duration cannot be empty'
      ),
      Billing_Time_Arrival: Yup.date().required(
        'Billing Time Arrival cannot be empty'
      ),
      Billing_Time_Depart: Yup.date().min(
        Yup.ref('Billing_Time_Arrival'),
        'Must Greater than Time Arrival'
      )
    }),
    onSubmit: async (values) => {
      try {
        const { result } = await SettlementAPI.UpdateBillingBerthDur({
          SR_Code: data.Code,
          Billing_Berth_Dur: parseFloat(values.Billing_Berth_Dur_Detail),
          Billing_Time_Arrival: moment(
            values.Billing_Time_Arrival
          ).toISOString(),
          Billing_Time_Depart: moment(values.Billing_Time_Depart).toISOString()
        })
        if (result) {
          setAlert({
            msg: 'Update data has been successful',
            success: true,
            visible: true,
            type: 'update'
          })
        } else {
          setAlert({
            msg: 'Update data has failed',
            success: false,
            visible: true,
            type: 'update'
          })
        }
      } catch (error) {
        return error
      }
    }
  })

  //HANDLE BTD CHANGE
  useEffect(() => {
    const { values } = SettlementSRForm
    if (values.Billing_Time_Depart && isFromManualTriggerDepart) {
      const Start_Date = moment(values.Billing_Time_Arrival)
      const Finish_Date = moment(values.Billing_Time_Depart)
      const min = Finish_Date.diff(Start_Date, 'minutes', true)
      const hour = parseFloat((min / 60).toFixed(2))
      SettlementSRForm.setFieldValue('Billing_Berth_Dur_Detail', hour)
      setIsFromManualTriggerDepart(false)
    }
  }, [SettlementSRForm.values.Billing_Time_Depart])

  //HANDLE BTA CHANGE
  useEffect(() => {
    const { Billing_Berth_Dur_Detail, Billing_Time_Arrival } =
      SettlementSRForm.values
    if (
      Billing_Berth_Dur_Detail &&
      Billing_Time_Arrival &&
      isFromManualTriggerArrival
    ) {
      const min = Math.ceil(Billing_Berth_Dur_Detail * 60)
      const planDeparture = moment(Billing_Time_Arrival)
        .add(min, 'minutes')
        .format('MM/DD/yyyy HH:mm')

      SettlementSRForm.setFieldValue('Billing_Time_Depart', planDeparture)
      setIsFromManualTriggerArrival(false)
    }
  }, [SettlementSRForm.values.Billing_Time_Arrival])

  // HANDLE DELAY DURATION CHANGE
  useEffect(() => {
    const { Billing_Time_Arrival, Billing_Berth_Dur_Detail } =
      SettlementSRForm.values
    if (Billing_Time_Arrival && isFromManualTrigger) {
      const min = Billing_Berth_Dur_Detail * 60

      const finishDate = moment(Billing_Time_Arrival)
        .add(min, 'minutes')
        .format('MM/DD/yyyy HH:mm')

      SettlementSRForm.setFieldValue('Billing_Time_Depart', finishDate)
      setIsFromManualTrigger(false)
    }
  }, [SettlementSRForm.values.Billing_Berth_Dur_Detail])

  useEffect(() => {
    return () => clearTimeout(timerInbound)
  }, [])

  const _checkValueListType = (e) => {
    let value

    if (e.Data_Field === 'cutting_mixing_type_code') {
      value = data.Cutting_Mixing_Type
    } else if (e.Data_Field === 'recipient_code') {
      value = data.Recipient
    } else if (e.Data_Field === 'agent_code') {
      value = data.Agent_Name
    } else if (e.Data_Field === 'jetty_code') {
      value = data.Jetty_Name
    } else if (e.Data_Field === 'vessel_clearance_code') {
      value = data.Vessel_Clearance
    } else if (e.Data_Field === 'vessel_code') {
      value = data.Vessel_Name
    } else if (e.Data_Field === 'plb_type_code') {
      value = data.Plb_Type
    } else if (e.Data_Field === 'origin_code') {
      value = data.Origin
    } else if (e.Data_Field === 'destination_code') {
      value = data.Destination
    }
    return { value: value }
  }

  const _checklistCell = ({ dataItem, field, dataIndex }) => {
    let indexFind
    props.data.WorkPermits.find((event) =>
      event.Code === dataItem.Code && event.Is_Active
        ? (indexFind = true)
        : (indexFind = false)
    )
    return (
      <td style={{ textAlign: 'center' }} id={`m_grid-${field}-${dataIndex}`}>
        <FormControlLabel
          style={{ marginRight: '0px' }}
          disabled={true}
          control={
            <CustomCheckbox id='m_grid_checklist-btn' checked={indexFind} />
          }
        />
      </td>
    )
  }

  const _planStartEndCell = ({ dataItem, dataIndex }, field) => {
    return (
      <td id={`m_grid-${field}-${dataIndex}`}>
        {ConvertLocalTimeGet(
          props.data.Location,
          dataItem[field],
          formatMonthName
        )}
      </td>
    )
  }

  const _commonCell = ({ dataItem, field, dataIndex }) => {
    return <td id={`m_grid-${field}-${dataIndex}`}>{dataItem[field]}</td>
  }

  const _formattingDate = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm') === 'Invalid date'
      ? ''
      : moment(
          ConvertLocalTimeGet(data.Location, date, 'YYYY/MM/DD HH:mm')
        ).format('DD/MM/YYYY HH:mm')
  }

  const _TopForm = (left) => {
    return (
      <Form padding='0px 30px 0px 30px'>
        {left && (
          <Input
            id='sett-sr_mdl-sr_type-txt'
            name='Sr_Type'
            label='Service Request Type'
            InputProps={{ readOnly: true }}
            value={props.data.Sr_Type}
          />
        )}
        {!left && (
          <Input
            id='sett-sr_mdl-sr_category-txt'
            name='Sr_Category'
            label='Service Category'
            InputProps={{ readOnly: true }}
            value={props.data.Sr_Category}
          />
        )}
      </Form>
    )
  }

  const _ContractNumberForm = () => {
    let convertMasterData = ''
    let filterMasterData = []
    if (dataContract && dataContract.length > 0) {
      filterMasterData = dataContract.filter(
        (contract) =>
          contract.AgreementNo == props.data.Agreement_No &&
          contract.SapContractNo == props.data.Contract_No
      )
      if (filterMasterData && filterMasterData.length > 0) {
        convertMasterData = filterMasterData[0].SAP_agrement_no
      }
    }
    return (
      <Form padding='0px 30px 0px 30px'>
        <Input
          id='sett-sr_mdl-contract_no-txt'
          name='Contract_No'
          label='Contract Number'
          InputProps={{ readOnly: true }}
          value={convertMasterData}
        />
      </Form>
    )
  }

  const _ContractTitleForm = () => {
    let convertMasterData = ''
    let filterMasterData = []
    if (dataContract && dataContract.length > 0) {
      filterMasterData = dataContract.filter(
        (contract) =>
          contract.AgreementNo == props.data.Agreement_No &&
          contract.SapContractNo == props.data.Contract_No
      )
      if (filterMasterData && filterMasterData.length > 0) {
        convertMasterData = filterMasterData[0].ContractTitle
      }
    }
    return (
      <Form padding='0px 30px 0px 30px'>
        <Input
          id='sett-sr_mdl-contract_title-txt'
          name='contract_title'
          label='Contract Title'
          InputProps={{ readOnly: true }}
          value={convertMasterData}
        />
      </Form>
    )
  }

  const _POListForm = () => {
    let convertMasterData = ''
    let filterMasterData = []
    if (contractTitle && contractTitle.length > 0) {
      filterMasterData = contractTitle.filter(
        (poList) => props.data.Contract_Title == poList.code
      )
      if (filterMasterData && filterMasterData.length > 0) {
        convertMasterData = filterMasterData[0].po_no
      }
    }
    return (
      <Form padding='0px 30px 0px 30px'>
        <Input
          id='sett-sr_mdl-po_list-txt'
          name='po_list'
          label='PO List'
          InputProps={{ readOnly: true }}
          value={convertMasterData}
        />
      </Form>
    )
  }

  const _BottomForm = (left) => {
    const styles = {
      helperText: {
        fontSize: '10px',
        color: '#777777',
        marginTop: '2px',
        marginBottom: '5px'
      },
      addressText: {
        top: -19,
        fontSize: '12px',
        color: '#777777',
        position: 'relative'
      }
    }

    let recipient =
      dataRecipient.length > 0
        ? dataRecipient.filter((e) => e.code === data.Recipient_Code).length > 0
          ? dataRecipient.filter((e) => e.code === data.Recipient_Code)[0]
              .address
          : ''
        : ''
    recipient =
      recipient.length > 60 ? recipient.substring(0, 60) + '...' : recipient

    return (
      <Form padding='0px 30px 0px 30px'>
        {srMapping.length > 0 &&
          srMapping
            .filter((item) => {
              if (
                FormSubmit.values.Plb_Goods === false &&
                (item.Data_Field === 'plb_type_code' ||
                  item.Data_Field === 'no_aju' ||
                  item.Data_Field === 'no_pen')
              ) {
                return false
              }

              return true
            })
            .filter((sr, i) => (left ? i % 2 == 0 : i % 2 != 0))
            .map((dt, i) => {
              if (dt.Data_Type.toLowerCase() == 'calendar') {
                return (
                  <Input
                    label={dt.Data_Desc}
                    name={dt.Data_Field_Port}
                    value={moment(props.data[dt.Data_Field_Port]).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                    id={`sett-sr-mdl-${dt.Data_Field_Port}-text`}
                    InputProps={{ readOnly: dt.Disable }}
                  />
                )
              } else if (dt?.Data_Field === 'warehouse_name') {
                return (
                  <Input
                    id={`sett-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={dataSr?.Warehouse_Name}
                  />
                )
              } else if (dt?.Data_Field === 'document_type') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={dataSr?.Document_Type}
                  />
                )
              } else if (dt?.Data_Field === 'truck_no') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={dataSr?.Truck_No}
                  />
                )
              } else if (dt?.Data_Field === 'room') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={dataSr?.Room}
                  />
                )
              } else if (dt?.Data_Field === 'rent_area') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={dataSr?.Rent_Area}
                  />
                )
              } else if (dt?.Data_Field === 'waste_type') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={dataSr?.Waste_Type}
                  />
                )
              } else if (dt?.Data_Field === 'waste_characteristic') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={dataSr?.Waste_Characteristic}
                  />
                )
              } else if (dt?.Data_Field === 'location') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={dataSr?.Location}
                  />
                )
              } else if (dt?.Data_Field === 'vehicle_type') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={dataSr?.Vehicle_Type}
                  />
                )
              } else if (dt.Data_Type.toLowerCase() == 'text') {
                if (dt.Data_Field_Port == 'Plan_Berth_Dur') {
                  return (
                    <Input
                      label={dt.Data_Desc}
                      name={dt.Data_Field_Port}
                      value={GetDurationMinutes(
                        props.data.Plan_Arrival,
                        props.data.Plan_Depart,
                        0
                      )}
                      id={`sett-sr-mdl-${dt.Data_Field_Port}-text`}
                      InputProps={{ readOnly: dt.Disable }}
                    />
                  )
                }
                return (
                  <Input
                    label={dt.Data_Desc}
                    name={dt.Data_Field_Port}
                    value={props.data[dt.Data_Field_Port]}
                    id={`sett-sr-mdl-${dt.Data_Field_Port}-text`}
                    InputProps={{ readOnly: dt.Disable }}
                  />
                )
              } else if (dt.Data_Field === 'jetty_position') {
                return (
                  <Input
                    form={FormSubmit}
                    label={dt.Data_Desc}
                    name={dt.Data_Field_Port}
                    id={`sett-sr-mdl-${dt.Data_Field_Port}-text`}
                    type='number'
                    InputProps={{ readOnly: dt.Disable }}
                  />
                )
              } else if (dt.Data_Type.toLowerCase() == 'valuelist') {
                return (
                  <>
                    <Input
                      style={{ marginTop: '7px' }}
                      label={dt.Data_Desc}
                      name={dt.Data_Field_Port}
                      value={_checkValueListType(dt).value}
                      id={`sett-sr-mdl-${dt.Data_Field_Port}-text`}
                      InputProps={{ readOnly: dt.Disable }}
                    />
                    {dt.Data_Field === 'recipient_code' && (
                      <div style={styles.addressText}>{recipient}</div>
                    )}
                  </>
                )
              } else if (dt.Data_Type === 'boolean') {
                return (
                  <div style={{ height: '67px' }} key={i}>
                    <Checkbox
                      readOnly={true}
                      form={FormSubmit}
                      name={dt.Data_Field_Port}
                      title={dt.Data_Desc}
                    />
                  </div>
                )
              } else if (dt.Data_Type === 'form_detail') {
                return (
                  <div style={{ height: '67px' }} key={i}>
                    <ButtonDetail
                      title={dt.Data_Desc}
                      isCCU={true}
                      id={`sett-sr-mdl-${dt.Data_Field}-btn`}
                      onClick={
                        dt.Data_Field === 'ccu_detail'
                          ? () => {
                              setModalPermitCCU({ type: 'ccu', status: true })
                            }
                          : dt.Data_Field === 'work_permit_detail'
                          ? () => {
                              setModalPermitCCU({
                                type: 'permit',
                                status: true
                              })
                            }
                          : null
                      }
                    >
                      {dt.Data_Desc}
                    </ButtonDetail>
                    {dt.Data_Field === 'ccu_detail' && (
                      <div style={styles.helperText}>
                        {props.data.CCUS && props.data.CCUS.length !== 0
                          ? `You have added ${props.data.CCUS.length} CCU`
                          : 'You haven\'t added CCU'}
                      </div>
                    )}
                    {dt.Data_Field === 'work_permit_detail' && (
                      <div style={styles.helperText}>
                        {props.data.WorkPermits &&
                        props.data.WorkPermits.length !== 0
                          ? `You have added ${props.data.WorkPermits.length} Work Permit`
                          : 'You haven\'t added Work Permit'}
                      </div>
                    )}
                  </div>
                )
              }
            })}
      </Form>
    )
  }

  const showArivalTime = () => {
    return (
      <WrapperValue
        style={{
          justifyContent: 'space-between',
          width: '35rem'
        }}
      >
        <Input
          id='sett-sr_mdl-actual_time_arival-txt'
          name='Actual_Time_Arrival'
          label='Actual Time Arrival'
          InputProps={{ readOnly: true }}
          disabled={true}
          value={ConvertLocalTimeGet(
            data.Location,
            data.Ata_No_Format,
            formatMonthName
          )}
        />
        <Input
          id='sett-sr_mdl-billing_time_arrival-txt'
          name='Billing_Time_Arrival'
          label='Billing Time Arrival'
          InputProps={{ readOnly: true }}
          disabled={true}
          value={ConvertLocalTimeGet(
            data.Location,
            data.Billing_Time_Arrival,
            formatMonthName
          )}
        />
      </WrapperValue>
    )
  }

  const showDepartTime = () => {
    return (
      <WrapperValue
        style={{
          justifyContent: 'space-between',
          width: '35rem'
        }}
      >
        <Input
          id='sett-sr_mdl-actual_time_depart-txt'
          name='Actual_Time_Depart'
          label='Actual Time Depart'
          InputProps={{ readOnly: true }}
          disabled={true}
          value={ConvertLocalTimeGet(
            data.Location,
            data.Atd_No_Format,
            formatMonthName
          )}
        />
        <Input
          id='sett-sr_mdl-billing_time_depart-txt'
          name='Billing_Time_Depart'
          label='Billing Time Depart'
          InputProps={{ readOnly: true }}
          disabled={true}
          value={ConvertLocalTimeGet(
            data.Location,
            data.Billing_Time_Depart,
            formatMonthName
          )}
        />
      </WrapperValue>
    )
  }
  const showDurationTime = () => {
    return (
      <WrapperValue
        style={{
          justifyContent: 'space-between',
          width: '35rem'
        }}
      >
        <Input
          id='sett-sr_mdl-actual_time_duration-txt'
          name='Actual_Time_Duration'
          label='Actual Time Duration'
          InputProps={{ readOnly: true }}
          disabled={true}
          value={GetDurationMinutes(data.Ata_No_Format, data.Atd_No_Format, 0)}
        />
        <Input
          id='sett-sr_mdl-billing_time_duration-txt'
          name='Billing_Time_Duration'
          label='Billing Time Duration'
          InputProps={{ readOnly: true }}
          disabled={true}
          value={GetDurationMinutes(
            data.Billing_Time_Arrival,
            data.Billing_Time_Depart,
            0
          )}
        />
      </WrapperValue>
    )
  }

  return (
    <>
      <FormContainer>
        <LeftFormContainer>
          <Container>
            <Row>
              {_TopForm(true)}
              {_TopForm(false)}
            </Row>
            <Row>
              {_ContractNumberForm()}
              {_ContractTitleForm()}
            </Row>
            <div>
              {_POListForm()}
              <BorderLine />
            </div>
            <Row></Row>
            <Row>
              {_BottomForm(true)}
              {_BottomForm(false)}
            </Row>
            <Row>
            </Row>
          </Container>
        </LeftFormContainer>
        <RightFormContainer>
          <Form>
            <>
              <ContainerTop align='baseline'>
                <TextTop1>Created By</TextTop1>
                <TextTop2>{data?.Created_By.split('(')[0] || ''}</TextTop2>
              </ContainerTop>
              <ContainerTop align='center'>
                <TopIcon src={Images.company} />
                <TextTop3>
                  {!_.isUndefined(data?.Created_By.split('(')[1])
                    ? data?.Created_By.split('(')[1].split(')')[0]
                    : '-'}
                </TextTop3>
                <TopIcon src={Images.time} />
                <TextTop3>
                  {_formattingDate(data.Created_Date_No_Format)}
                </TextTop3>
              </ContainerTop>
              <WrapperValue>
                <TitleValue>Estimation Date</TitleValue>
                <SVGIcon src={Images.schedule} />
              </WrapperValue>
              <WrapperValue>
                <TitleBerthing>
                  {_formattingDate(data.Eta_No_Format)}
                </TitleBerthing>
                <Text regular={true}>to</Text>
                <TitleBerthing>
                  {_formattingDate(data.Etd_No_Format)}
                </TitleBerthing>
              </WrapperValue>
              {showTime && showArivalTime()}
              {showTime && showDepartTime()}
              {showTime && showDurationTime()}
            </>
            <Input
              id='sr-sr_mdl-remarks-txt'
              multiline
              rowsMax={3}
              label='Request Description'
              variant='outlined'
              name='Remarks'
              InputProps={{ readOnly: true }}
              value={data.Remarks}
              inputProps={{
                tabIndex: 11
              }}
              disabled={true}
            />
            <Input
              id='sr-sr_mdl-client_name-txt'
              name='Client_Name'
              label='Client Name'
              InputProps={{ readOnly: true }}
              disabled={true}
              value={data.Client}
            />
            <Input
              id='sr-sr_mdl-location-txt'
              name='Location'
              label='Location'
              InputProps={{ readOnly: true }}
              disabled={true}
              value={data.Location}
            />
            <Input
              id='sr-sr_mdl-sr_number-txt'
              name='SR_Number'
              label='SR Number'
              InputProps={{ readOnly: true }}
              disabled={true}
              value={data.Sr_No}
            />
            <Input
              id='sr-sr_mdl-priority-txt'
              name='Priority'
              label='Priority'
              InputProps={{ readOnly: true }}
              disabled={true}
              value={data.Priority}
            />
            {data.Revised_From === 'Finalized' &&
            data.Sr_Status !== 'Finalized' &&
            data.Revised_Remarks ? (
              <>
                <Input
                  id='sr-sr_mdl-unfinalized_remarks-txt'
                  multiline
                  rowsMax={3}
                  label='Unfinalized Description'
                  variant='outlined'
                  name='Unfinalized Remarks'
                  InputProps={{ readOnly: true }}
                  value={data.Revised_Remarks}
                  inputProps={{
                    tabIndex: 11
                  }}
                />
                <RevisedBy>
                  Unfinalized on {_formattingDate(data.Revised_Date)} by{' '}
                  {data.Revised_By}
                </RevisedBy>
              </>
            ) : data.Revised_From === 'Waiting for Confirmation' &&
              data.Revised_Remarks ? (
              <>
                <Input
                  id='sr-sr_mdl-revise_remarks-txt'
                  multiline
                  rowsMax={3}
                  label='Revise Description'
                  variant='outlined'
                  name='Revise Remarks'
                  InputProps={{ readOnly: true }}
                  value={data.Revised_Remarks}
                  inputProps={{
                    tabIndex: 11
                  }}
                />
                <RevisedBy>
                  Revised on {_formattingDate(data.Revised_Date)} by{' '}
                  {data.Revised_By}
                </RevisedBy>
              </>
            ) : null}
          </Form>
        </RightFormContainer>
      </FormContainer>
      <Alerts
        id='settlement-sr_mdl-alert'
        msg={showAlert.msg}
        close={() => {
          setAlert({ msg: '', success: true, visible: false, type: '' })
        }}
        type={showAlert.type}
        open={showAlert.visible}
        success={showAlert.success}
      />
      {modalPermitCCU.status && (
        <ModalWorkPermitCCU
          dataSr={props.data}
          handleClose={handleCloseModal}
          modalPermitCCU={modalPermitCCU}
          DocGrid={modalPermitCCU.type === 'ccu' ? DocGridCCU : DocGridPermit}
          data={
            modalPermitCCU.type === 'ccu' ? props.data.CCUS : dataMasterPermit
          }
          title={`${
            modalPermitCCU.type === 'ccu' ? 'CCU Detail' : 'Work Permit'
          }`}
          commonCell={_commonCell}
          checklistCell={_checklistCell}
          planStartEndCell={_planStartEndCell}
        />
      )}
    </>
  )
}

export default ServiceRequestModalContent
