/* eslint-disable react/no-unknown-property */
import _ from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'

import { Colors } from '../../styles'
import { ConvertLocalTimeGet, prefix, setToBase64 } from '../../utils'
import { SettlementAPI } from '../../api'
import { ActionCell } from '../../styles/global.styled'
import DocumentGridColumn from './column.modal_doc.json'
import { ModalDel, Alerts, CloudIcon, XlsxIcon, DocxIcon, PdfIcon, Loading } from '../../components'
import {
  UploadButton,
  DownloadButton,
  ActionContainer,
  TotalPackageText,
  LoadingContainer,
  ModalDocContainer,
  DownloadAllButton,
  SettlementModalGridContainer,
  DocumentUploadButtonContainer,
  DocumentDownloadAllButtonContainer,
  TextLink,
} from './settlement.styled'
import { Images } from '../../assets'
import LogApi from '../../api/master-data/log'
import { Lookup } from '../../utils'
import { setToBase64SR } from '../../utils/prefixBase64'

const DocumentModalContent = (props) => {
  const { isEdit, pathRole, data, fileSize } = props

  const [dataId, setDataId] = useState(null)
  // const [fileSize, setFileSize] = useState(20)
  const [fileType, setFileType] = useState('')
  const [attachments, setAttachments] = useState([])
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [attachmentTypeCode, setAttachmentTypeCode] = useState('')
  const [fileDownload, setFileDownload] = useState({ filename: '', dataBase64: '' })
  const [DocGrid, setDocGrid] = useState(DocumentGridColumn)
  const [screenSize, setScreenSize] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isRefresh, setIsRefresh] = useState(false)
  const [showAlert, setAlert] = useState({ visible: false, msg: '', success: false })
  const { usersProfileReducer } = useSelector((state) => state)

  const heightFactor = isEdit ? 455 : attachments.length ? 440 : 390
  const documentGridHeight =
    window.innerHeight - heightFactor > 200 ? window.innerHeight - heightFactor : 200

  const _getAttachmentTypeCode = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"Attachment_Type"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await SettlementAPI.GetAllFromTableEnum(0, 100, filter)
      data.forEach((v) => {
        if (v.Value.toLowerCase() === 'settlement attachment') {
          setAttachmentTypeCode(v.Code)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  /*Fix Double Get same Endpoint*/
  // const _getFileSize = async () => {
  //   try {
  //     const filter =
  //       '[{"field":"type","type":"text","value":"File_Size"},{"field":"is_active","type":"bool","value":"true"}]'
  //     const { data } = await SettlementAPI.GetAllFromTableEnum(0, 100, filter)
  //     setFileSize(parseInt(data[0].Value))
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const _getFileType = async () => {
    try {
      let parsedData = []
      const filterDocument =
        '[{"field":"type","type":"text","value":"File_Document"},{"field":"is_active","type":"bool","value":"true"}]'
      const documentEnum = await SettlementAPI.GetAllFromTableEnum(0, 2, filterDocument)
      if (documentEnum.data.length) {
        parsedData.push(documentEnum.data[0].Value)
      }
      const filterImage =
        '[{"field":"type","type":"text","value":"File_Image"},{"field":"is_active","type":"bool","value":"true"}]'
      const imageEnum = await SettlementAPI.GetAllFromTableEnum(0, 2, filterImage)
      if (imageEnum.data.length) {
        parsedData.push(imageEnum.data[0].Value)
      }
      parsedData = parsedData.join('|')
      parsedData = parsedData.split('|').join(', .')
      setFileType('.' + parsedData)
    } catch (error) {
      console.log(error)
    }
  }
  const _getAllAttachment = async () => {
    try {
      setIsLoading(true)
      const filter = `[{"field":"sr_code","type":"text","value":"${props.data.Code}"}]`
      const { data } = await SettlementAPI.GetAllAttachment(0, 1000, filter)
      // filter by Sr_No on FE just in case...
      let filteredData = data ? data.filter((v) => v.Sr_Code === props.data.Code) : []
      // filter for data that doesn't have 'Deleted_By' flag
      filteredData = filteredData.filter((e) => e.Deleted_By === '')
      // sort data
      filteredData = _.orderBy(filteredData, ['Code'], ['desc'])

      let filteredDataAttachment = []
      for (const data of filteredData) {
        filteredDataAttachment.push({
          ...data,
          ['Filename_Base64']: await data.Path.toLowerCase().includes('lbs-attachment') ? setToBase64SR(data.Filename) : setToBase64(data.Filename),
        })
      }
      setAttachments(filteredDataAttachment)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    _getAll()
  }, [])

  const _getAll = async () => {
    // await _getFileSize()
    await _getFileType()
    await _getAllAttachment()
    await _getAttachmentTypeCode()
  }

  const _getFileIcon = (props) => {
    const filename = props.dataItem.Filename
    const extension = filename.split('.')[filename.split('.').length - 1].toLowerCase()

    if (extension === 'doc' || extension === 'docx') {
      return <DocxIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'xls' || extension === 'xlsx') {
      return <XlsxIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'pdf') {
      return <PdfIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
      return <img src={Images.filesIconImg} dataIndex={props.dataIndex} />
    }
  }

  const _fileNameCell = (props) => {
    let counting = props.dataItem.Filename.split('.')[0].split('_').length
    let origin = props.dataItem.Filename.split('.')[0].split('_')[counting -1]
    let nameFile = props.dataItem.Filename.split('_'+origin.toString())[0] +'.'+ props.dataItem.Filename.split('.')[1]
    return (
      <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 75 }}>
        <span style={{ marginRight: 10, fontSize: 30 }}>{_getFileIcon(props)}</span>
        {moment(props.dataItem.Created_Date)<moment('2021-03-01 20:00Z')?
          <TextLink
            onClick={() => {
              if (
                props.dataItem.Filename.includes('.doc') ||
              props.dataItem.Filename.includes('.xls')
              ) {
                window.open(props.dataItem.Path)
              } else {
                let win = window.open()
                win.document.write(`<iframe 
            style="
            width:100%;
            height:100%;
            "
            src="${props.dataItem.Filename_Base64}" />`)
              }
            }}
          >
            {props.dataItem.Filename}
          </TextLink>
          :
          <a href={props.dataItem.Path} target='_blank' rel='noopener noreferrer'>
            {nameFile}
          </a>
        }
      </td>
    )
  }

  const _uploadOnCell = (props) => (
    <td>{ConvertLocalTimeGet(data.Location, props.dataItem.Created_Date, 'DD/MM/YYYY HH:mm')}</td>
  )

  const _fileSizeCell = (props) => <td>{parseInt(+props.dataItem.Filesize / 1000) + ' kb'}</td>

  const _rowNumberCell = (props) => (
    <td>
      <p
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.dataIndex + 1}
      </p>
    </td>
  )

  const _centeredCell = (props) => {
    return <td style={{ textAlign: 'center' }}>{props.dataItem[props.field]}</td>
  }

  const _actionCell = (props) => (
    <td>
      <ActionContainer>
        {pathRole.allowDownload && (
          <>
            <DownloadButton
              id={`settlement-doc_mdl-download-one-btn-${props.dataIndex + 1}`}
              onClick={() => {
                _downloadFileFromServer(props)
              }}
              style={{ cursor: 'pointer' }}
            >
              <CloudIcon style={{ marginRight: 6.4 }} />
                  Download
            </DownloadButton>
            <a
              href={fileDownload.dataBase64}
              download={fileDownload.Filename}
              id={`download-${props.dataIndex + 1}`}
              style={{ display: 'none' }}
            >
                  download
            </a>
          </>
        )}
        {isEdit && pathRole.allowDelete && props.dataItem.Filesource !== 'Evidence' &&
          props.dataItem.Attachment_Type.toLowerCase() == 'settlement attachment' && (
          <ActionCell
            id='settlement-doc_mdl-action-delete-td'
            onClick={() => {
              setDataId(props.dataItem.ID)
              setShowModalDelete(true)
            }}
          >
            <DeleteIcon htmlColor={Colors.black} />
          </ActionCell>
        )}
      </ActionContainer>
    </td>
  )

  const _deleteModal = () => (
    <div>
      <ModalDel
        submitDelete={_handleDocumentDelete}
        deleteText='Are you sure to delete the data ?'
        toggleDialog={() => setShowModalDelete(false)}
      />
    </div>
  )

  const _handleDocumentDelete = async () => {
    if (isEdit) {
      try {
        const response = await SettlementAPI.DeleteDocument(dataId)
        if (response && response.result) {
          setAlert({
            msg: 'Document Deletion has been successful',
            success: true,
            visible: true,
          })
        } else {
          setAlert({ msg: 'Cannot Delete Document', success: false, visible: true })
        }
      } catch (error) {
        console.log(error)
      }
      setDataId(null)
      _getAllAttachment()
    }
    setShowModalDelete(false)
  }

  const _downloadAll = () => {
    for (let i = 0; i < attachments.length; i++) {
      document.getElementById(`settlement-doc_mdl-download-one-btn-${i + 1}`).click()
    }
  }

  const _downloadFileFromServer = async (props) => {
    try {
      let response = null
      if(props.dataItem.Path.toLowerCase().includes('lbs-attachment')) {
        response = await SettlementAPI.GetAttachmentFileSR(props.dataItem.Filename)
      } else {
        response = await SettlementAPI.GetAttachmentFile(props.dataItem.Filename)
      }
      const baseFile64 = response.data
      const prefixBase64 = prefix(props.dataItem.Filename)
      const dataBase64 = prefixBase64 + baseFile64
      setFileDownload({ Filename: props.dataItem.Filename, dataBase64: dataBase64 })
      document.getElementById('download-1').click()
    } catch (err) {
      console.log(err)
    }
  }

  const _handleFileUpload = (e) => {
    setIsLoading(true)
    const fileSizeLimit = fileSize * 1024 * 1024
    let isLimitExceeded = false
    const formData = new FormData()
    const files = e.target.files
    for (let i = 0; i < files.length; i++) {
      const v = files[i]
      v.size > fileSizeLimit ? (isLimitExceeded = true) : null
      formData.append('file', v)
    }
    if (isLimitExceeded) {
      insertLog('filesize_exceed')
      setAlert({ msg: `One of the file size exceed ${fileSize} Mb`, success: false, visible: true })
      // document.getElementById('sr-doc_mdl-upload-form').reset()
      setIsLoading(false)
    } else {
      SettlementAPI.UploadAttachments(formData)
        .then(async (res) => {
          const { Company, UserName, AgentName } = usersProfileReducer

          const data = res.data.data
          for (let i = 0; i < data.length; i++) {
            const attachment = data[i]
            const docsObj = {
              Filename: attachment.FileName,
              Filesize: '' + attachment.FileSize,
              Filetype: 'document',
              Path: attachment.Path,
              Created_By: `${UserName} (${AgentName.length > 0 ? AgentName : Company})`,
            }
            try {
              await SettlementAPI.InsertAlldoc(docsObj)
              docsObj['Alldoc_Code'] = attachment.Code
              docsObj['Attachment_Type_Code'] = attachmentTypeCode
              docsObj['Sr_Code'] = props.data.Code
              await SettlementAPI.InsertDocument(docsObj)
            } catch (error) {
              console.log(error)
            }
          }
          _getAllAttachment()
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }

  const insertLog = async (type)=>{
    const dataInsert = {
      Timestamp:new Date(),
      Action: Lookup.LOG_ACTION.UPLOAD,
      Module: Lookup.LOG_MODULE.SETTLEMENT,
      Submodule: Lookup.LOG_SUB_MODULE.DOCUMENT,
      Detail: type === 'filesize_exceed'
        ? 'File Size cannot Exceed 5 MB'
        : '',
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From:'',
      To:'',
      Refno: props.data.Code
    }

    if (type !== '') {
      const { data } = await LogApi.PostDataLog(dataInsert)
      if (data && data.result)
        return data.result
    }
    else  return false
  }

  const _checkResize = () => {
    const modalEl = document.getElementById('mdl_comp_dialog_header').parentNode
    let referenceWidth = getComputedStyle(modalEl).width
    referenceWidth = parseFloat(referenceWidth.replace('px', ''))
    let data = DocGrid.map((dt) => {
      dt.responsive = dt.width === '*' ? (referenceWidth > 840 ? '*' : '150px') : dt.width
      return dt
    })
    data = data.map((dt) => {
      dt.responsive = dt.field === 'Action' ? (!isEdit ? dt.responsive : '80px') : dt.responsive
      return dt
    })
    setDocGrid(data)
    setIsRefresh(true)
  }

  useEffect(() => {
    _checkResize()
  }, [screenSize])

  useEffect(() => {
    _checkResize()
    window.addEventListener('resize', () => setScreenSize(window.innerWidth))
  }, [])

  useEffect(() => {
    if (isRefresh) {
      setIsRefresh(false)
    }
  }, [isRefresh])

  return (
    <>
      {showAlert.visible && (
        <Alerts
          id='settlement-alert'
          msg={showAlert.msg}
          close={() => setAlert({ msg: '', success: true, visible: false })}
          open={showAlert.visible}
          success={showAlert.success}
        />
      )}
      {showModalDelete && _deleteModal()}
      <ModalDocContainer>
        {isEdit && pathRole.allowUpload ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '63px',
            }}
          >
            <DocumentUploadButtonContainer>
              <form id='settlement-doc_mdl-upload-form' style={{ margin: 'auto' }}>
                <UploadButton
                  id='settlement-doc_mdl-upload-btn'
                  htmlFor='settlement-doc_mdl-upload-input-file'
                >
                  Upload File
                </UploadButton>
                <input
                  id='settlement-doc_mdl-upload-input-file'
                  multiple
                  type='file'
                  name='settlement-docs-attacments'
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    _handleFileUpload(e)
                    e.target.value = null
                  }}
                  accept={fileType}
                />
              </form>
            </DocumentUploadButtonContainer>
          </div>
        ) : attachments.length > 0 && pathRole.allowDownload ? (
          <DocumentDownloadAllButtonContainer>
            <DownloadAllButton id='settlement-doc_mdl-download-all-btn' onClick={_downloadAll}>
              <CloudIcon style={{ marginRight: 6.4 }} />
              Download All
            </DownloadAllButton>
          </DocumentDownloadAllButtonContainer>
        ) : null}
        {!isRefresh && (
          <SettlementModalGridContainer>
            <div
              style={{
                height: 35,
                display: 'flex',
                paddingRight: 5,
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <TotalPackageText>{`Total Document: ${attachments.length}`}</TotalPackageText>
            </div>
            {isLoading ? (
              <LoadingContainer>
                <Loading id='settlement-doc_mdl-loading-indicator' />
              </LoadingContainer>
            ) : (
              <Grid
                id='settlement-doc_mdl-all-data'
                data={attachments}
                total={attachments.length}
                style={{ height: documentGridHeight }}
                rowHeight={20}
                pageSize={8}
              >
                {DocGrid.map((e, index) =>
                  e.field === 'Action' ? (
                    <Column
                      key={index}
                      width={e.width}
                      title={e.title}
                      field={e.field}
                      cell={_actionCell}
                    />
                  ) : e.field === 'No' ? (
                    <Column
                      key={index}
                      width={e.width}
                      title={e.title}
                      cell={_rowNumberCell}
                    />
                  ) : e.field === 'Created_Date' ? (
                    <Column
                      key={index}
                      width={e.width}
                      title={e.title}
                      field={e.field}
                      cell={_uploadOnCell}
                    />
                  ) : e.field === 'Filename' ? (
                    <Column
                      key={index}
                      width={e.width}
                      title={e.title}
                      field={e.field}
                      cell={_fileNameCell}
                    />
                  ) : e.field === 'Filesize' ? (
                    <Column
                      key={index}
                      width={e.width}
                      title={e.title}
                      field={e.field}
                      cell={_fileSizeCell}
                    />
                  ) : (
                    <Column
                      key={index}
                      title={e.title}
                      field={e.field}
                      width={e.width}
                      cell={_centeredCell}
                    />
                  )
                )}
              </Grid>
            )}
          </SettlementModalGridContainer>
        )}
      </ModalDocContainer>
    </>
  )
}

export default DocumentModalContent
