/* eslint-disable react/no-unknown-property */

import _ from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'

import { Colors } from '../../styles'
import { Lookup, prefix, setToBase64 } from '../../utils'
import { LogAPI, ServiceRequestAPI } from '../../api'
import { ActionCell } from '../../styles/global.styled'
import DocumentGridColumn from './column.modal_doc.json'
import { ModalDel, CloudIcon, XlsxIcon, DocxIcon, PdfIcon, Loading } from '../../components'
import {
  SrModalGridContainer,
  ActionContainer,
  ModalDocContainer,
  DocumentUploadButtonContainer,
  UploadButton,
  DocumentDownloadAllButtonContainer,
  DownloadAllButton,
  DownloadButton,
  TotalPackageText,
  LoadingContainer,
  TextLink,
} from './service-request.styled'
import { Images } from '../../assets'

const DocumentModalContent = (props) => {
  const {
    isEdit,
    isDetail,
    setAlert,
    pathRole,
    isApproved,
    attachments,
    setAttachments,
    ServiceRequestForm,
    setIsSubmitButtonDisabled,
  } = props
  const [fileSize, setFileSize] = useState(20)
  const [fileType, setFileType] = useState('')
  const [dataIndex, setDataIndex] = useState(0)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [attachmentTypeCode, setAttachmentTypeCode] = useState('')
  const [fileDownload, setFileDownload] = useState({ filename: '', dataBase64: '' })
  const [DocGrid, setDocGrid] = useState(DocumentGridColumn)
  const [screenSize, setScreenSize] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isRefresh, setIsRefresh] = useState(false)
  const { usersProfileReducer } = useSelector((state) => state)

  const heightFactor = !isDetail
    ? 450
    : [...attachments].filter((e) => !e.Deleted_By).length
      ? 575
      : 525
  const documentGridHeight =
    window.innerHeight - heightFactor > 200 ? window.innerHeight - heightFactor : 200

  const _getAttachmentTypeCode = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"Attachment_Type"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllFromTableEnum(0, 100, filter)
      data.forEach((v) => {
        if (v.Value.toLowerCase() === 'sr attachment') {
          setAttachmentTypeCode(v.Code)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const _getFileSize = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"File_Size"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllFromTableEnum(0, 100, filter)
      setFileSize(parseInt(data[0].Value))
    } catch (error) {
      console.log(error)
    }
  }

  const _getFileType = async () => {
    try {
      let parsedData = []
      const filterDocument =
        '[{"field":"type","type":"text","value":"File_Document"},{"field":"is_active","type":"bool","value":"true"}]'
      const documentEnum = await ServiceRequestAPI.GetAllFromTableEnum(0, 100, filterDocument)
      if (documentEnum.data.length) {
        parsedData.push(documentEnum.data[0].Value)
      }
      const filterImage =
        '[{"field":"type","type":"text","value":"File_Image"},{"field":"is_active","type":"bool","value":"true"}]'
      const imageEnum = await ServiceRequestAPI.GetAllFromTableEnum(0, 100, filterImage)
      if (imageEnum.data.length) {
        parsedData.push(imageEnum.data[0].Value)
      }
      parsedData = parsedData.join('|')
      parsedData = parsedData.split('|').join(', .')
      setFileType('.' + parsedData)
    } catch (error) {
      console.log(error)
    }
  }

  const _getAllAttachment = async () => {
    try {
      const filter = `[{"field":"sr_code","type":"text","value":"${ServiceRequestForm.values.Code}"}]`
      const { data } = await ServiceRequestAPI.GetAllAttachment(0, 1000, filter)
      let filteredData = data
        ? data.filter((v) => v.Sr_Code === ServiceRequestForm.values.Code)
        : []
      filteredData = _.orderBy(filteredData, ['Code'], ['asc'])

      let filteredDataAttachment = []
      for (const data of filteredData) {
        filteredDataAttachment.push({
          ...data,
          ['Filename_Base64']: await setToBase64(data.Filename),
        })
      }

      setAttachments(filteredDataAttachment)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getAll()
  }, [])

  const _getAll = async () => {
    await _getFileSize()
    await _getFileType()
    await _getAllAttachment()
    await _getAttachmentTypeCode()
  }

  const _getFileIcon = (props) => {
    const filename = props.dataItem.Filename
    const extension = filename.split('.')[filename.split('.').length - 1].toLowerCase()

    if (extension === 'doc' || extension === 'docx') {
      return <DocxIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'xls' || extension === 'xlsx') {
      return <XlsxIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'pdf') {
      return <PdfIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
      return <img src={Images.filesIconImg} dataIndex={props.dataIndex} />
    }
  }

  const _fileNameCell = (props) => (
    <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 75 }}>
      <span style={{ marginRight: 10, fontSize: 30 }}>{_getFileIcon(props)}</span>
      {moment(props.dataItem.Created_Date)<moment('2021-03-01 20:00Z')?
        <TextLink
          onClick={() => {
            if (
              props.dataItem.Filename.includes('.doc') ||
            props.dataItem.Filename.includes('.xls')
            ) {
              window.open(props.dataItem.Path)
            } else {
              let win = window.open()

              win.document.write(`<iframe
          style="
          width:100%;
          height:100%;
          "
          src="${props.dataItem.Filename_Base64}" />`)
            }
          }}
        // style={{ color: 'blue', textDecoration: 'underline', width: '250px', cursor: 'pointer' }}
        >
          {props.dataItem.Filename}
        </TextLink>
        :
        <a href={props.dataItem.Path} target='_blank' rel='noopener noreferrer'>
          {props.dataItem.Filename}
        </a>
      }
    </td>
  )

  const _uploadOnCell = (props) => (
    <td>{moment(props.dataItem.Created_Date).format('DD/MM/YYYY HH:mm')}</td>
  )

  const _fileSizeCell = (props) => <td>{parseInt(+props.dataItem.Filesize / 1204) + ' kb'}</td>

  const _rowNumberCell = (props) => (
    <td>
      <p
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.dataIndex + 1}
      </p>
    </td>
  )

  const _centeredCell = (props) => {
    return <td style={{ textAlign: 'center' }}>{props.dataItem[props.field]}</td>
  }

  const _downloadAll = () => {
    for (let i = 0; i < attachments.length; i++) {
      document.getElementById(`sr-hidden-doc_mdl-download-one-btn-${i + 1}`).click()
    }
  }

  const _downloadFileFromServerHiddenAnchor = async (props) => {
    try {
      let response = null
      if(props.dataItem.Path.toLowerCase().includes('lbs-attachment')) {
        response = await ServiceRequestAPI.GetAttachmentFileSR(props.dataItem.Filename)
      } else {
        response = await ServiceRequestAPI.GetAttachmentFile(props.dataItem.Filename)
      }
      const baseFile64 = response.data
      const prefixBase64 = prefix(props.dataItem.Filename)
      const dataBase64 = prefixBase64 + baseFile64
      setFileDownload({ Filename: props.dataItem.Filename, dataBase64: dataBase64 })
      document.getElementById(`download-hidden-${props.dataIndex + 1}`).click()
    } catch (err) {
      console.log(err)
    }
  }

  const _downloadFileFromServer = async (props) => {
    try {
      let response = null
      if(props.dataItem.Path.toLowerCase().includes('lbs-attachment')) {
        response = await ServiceRequestAPI.GetAttachmentFileSR(props.dataItem.Filename)
      } else {
        response = await ServiceRequestAPI.GetAttachmentFile(props.dataItem.Filename)
      }
      const baseFile64 = response.data
      const prefixBase64 = prefix(props.dataItem.Filename)
      const dataBase64 = prefixBase64 + baseFile64
      setFileDownload({ Filename: props.dataItem.Filename, dataBase64: dataBase64 })
      document.getElementById(`download-${props.dataIndex + 1}`).click()
    } catch (err) {
      console.log(err)
    }
  }

  const _actionCell = (props) => (
    <td>
      <ActionContainer>
        {!isDetail && !isApproved ? (
          <>
            {pathRole.allowDelete && (
              <ActionCell
                id='sr-doc_mdl-action-delete-td'
                onClick={() => {
                  let dataIdx
                  if (props.dataItem.Code) {
                    dataIdx = attachments.findIndex((e) => e.Code === props.dataItem.Code)
                  } else {
                    dataIdx = attachments.findIndex(
                      (e) => e.Filename === props.dataItem.Filename && !e.Deleted_By
                    )
                  }
                  setDataIndex(dataIdx)
                  setShowModalDelete(true)
                }}
              >
                <DeleteIcon htmlColor={Colors.black} />
              </ActionCell>
            )}
          </>
        ) : (
          <>
            {pathRole.allowDownload && (
              <>
                <DownloadButton
                  id={`sr-doc_mdl-download-one-btn-${props.dataIndex + 1}`}
                  onClick={() => {
                    _downloadFileFromServer(props)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <CloudIcon style={{ marginRight: 6.4 }} />
                  Download
                </DownloadButton>
                <a
                  href={fileDownload.dataBase64}
                  download={fileDownload.Filename}
                  id={`download-${props.dataIndex + 1}`}
                  style={{ display: 'none' }}
                >
                  download
                </a>
              </>
            )}
          </>
        )}
      </ActionContainer>
    </td>
  )

  const _deleteModal = () => (
    <div>
      <ModalDel
        submitDelete={_handleDocumentDelete}
        deleteText='Are you sure to delete the data ?'
        toggleDialog={() => setShowModalDelete(false)}
      />
    </div>
  )

  const _handleDocumentDelete = () => {
    if (isEdit && !isDetail) {
      const newAttachments = [...attachments]
      // newAttachments[dataIndex].Deleted_By = 'user@mail.com'
      const userFromLocalStorage = JSON.parse(localStorage.getItem('user_info'))
      newAttachments[dataIndex].Deleted_By =
        userFromLocalStorage && userFromLocalStorage.User
          ? userFromLocalStorage.User.Email
          : 'admin@interport.co.id'
      setAttachments(newAttachments)
    } else if (!isDetail) {
      const newAttachments = [...attachments]
      newAttachments.splice(dataIndex, 1)
      setAttachments(newAttachments)
    }
    setShowModalDelete(false)
  }
  const insertLog = async (detail) => {
    const dataInsert = {
      Timestamp: moment().toISOString(),
      Action: Lookup.LOG_ACTION.UPLOAD,
      Module: Lookup.LOG_MODULE.SERVICE_REQUEST,
      Submodule: Lookup.LOG_SUB_MODULE.SERVICE_REQUEST + '[' + Lookup.LOG_ACTION.UPLOAD + ']',
      Detail: detail,
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From: '',
      To: '',
      Refno: ServiceRequestForm.values.Code,
    }

    return await LogAPI.PostDataLog(dataInsert)
  }
  const _handleFileUpload = (e) => {
    setIsLoading(true)
    setIsSubmitButtonDisabled(true)
    const fileSizeLimit = fileSize * 1024 * 1024
    let isLimitExceeded = false
    const formData = new FormData()
    const files = e.target.files
    for (let i = 0; i < files.length; i++) {
      const v = files[i]
      v.size > fileSizeLimit ? (isLimitExceeded = true) : null
      formData.append('file', v)
    }
    if (isLimitExceeded) {
      setAlert({ msg: 'One of the file size exceed 5 Mb', success: false, visible: true })
      insertLog('the file size exceed 5 Mb')

      document.getElementById('sr-doc_mdl-upload-form').reset()
      setIsLoading(false)
      setIsSubmitButtonDisabled(false)
    } else {
      ServiceRequestAPI.UploadAttachments(formData)
        .then(async (res) => {
          const { Company, UserName, AgentName } = usersProfileReducer
          const data = res.data.data
          const result = []
          if (data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              const attachment = data[i]
              const docsObj = {
                Filename: attachment.FileName,
                Filesize: '' + attachment.FileSize,
                Filetype: 'document',
                Path: attachment.Path,
                Created_By: `${UserName} (${AgentName.length > 0 ? AgentName : Company})`,
              }
              try {
                await ServiceRequestAPI.InsertAlldoc(docsObj)
                docsObj['Alldoc_Code'] = attachment.Code
                docsObj['Attachment_Type_Code'] = attachmentTypeCode
                docsObj['Filename_Base64'] = await setToBase64(attachment.FileName)
                docsObj['Created_Date'] = new Date()
                result.push(docsObj)
              } catch (error) {
                console.log(error)
              }
            }
          }
          setAttachments([...attachments, ...result])
          setIsLoading(false)
          setIsSubmitButtonDisabled(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
          setIsSubmitButtonDisabled(false)
        })
    }
  }

  const _checkResize = () => {
    const modalEl = document.getElementById('mdl_comp_dialog_header').parentNode
    let referenceWidth = getComputedStyle(modalEl).width
    referenceWidth = parseFloat(referenceWidth.replace('px', ''))
    let data = DocGrid.map((dt) => {
      dt.responsive = dt.width === '*' ? (referenceWidth > 840 ? '*' : '150px') : dt.width
      return dt
    })
    data = data.map((dt) => {
      dt.responsive =
        dt.field === 'Action' ? (isDetail || isApproved ? dt.responsive : '80px') : dt.responsive
      return dt
    })
    setDocGrid(data)
    setIsRefresh(true)
  }

  useEffect(() => {
    _checkResize()
  }, [screenSize])

  useEffect(() => {
    _checkResize()
    window.addEventListener('resize', () => setScreenSize(window.innerWidth))
  }, [])

  useEffect(() => {
    if (isRefresh) {
      setIsRefresh(false)
    }
  }, [isRefresh])

  return (
    <div>
      {showModalDelete && _deleteModal()}
      <ModalDocContainer>
        {!isDetail && !isApproved && pathRole.allowUpload ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '63px',
            }}
          >
            <DocumentUploadButtonContainer>
              <form id='sr-doc_mdl-upload-form' style={{ margin: 'auto' }}>
                <UploadButton id='sr-doc_mdl-upload-btn' htmlFor='sr-doc_mdl-upload-input-file'>
                  Upload File
                </UploadButton>
                <input
                  id='sr-doc_mdl-upload-input-file'
                  multiple
                  type='file'
                  name='sr-docs-attacments'
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    _handleFileUpload(e)
                    e.target.value = null
                  }}
                  accept={fileType}
                />
              </form>
            </DocumentUploadButtonContainer>
          </div>
        ) : attachments.length > 0 && pathRole.allowDownload ? (
          <DocumentDownloadAllButtonContainer>
            <DownloadAllButton id='sr-doc_mdl-download-all-btn' onClick={_downloadAll}>
              <CloudIcon style={{ marginRight: 6.4 }} />
              Download All
            </DownloadAllButton>
          </DocumentDownloadAllButtonContainer>
        ) : null}
        {!isRefresh && (
          <SrModalGridContainer>
            <div
              style={{
                height: 35,
                display: 'flex',
                paddingRight: 5,
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <TotalPackageText>
                {`Total Document: ${[...attachments].filter((e) => !e.Deleted_By).length}`}
              </TotalPackageText>
            </div>
            {isLoading ? (
              <LoadingContainer>
                <Loading id='sr-doc_mdl-loading-indicator' />
              </LoadingContainer>
            ) : (
              <Grid
                id='sr-doc_mdl-all-data'
                data={[...attachments].filter((e) => !e.Deleted_By)}
                total={attachments.length}
                style={{ height: documentGridHeight }}
                rowHeight={20}
                pageSize={8}
              >
                {DocGrid.map((e, index) =>
                  e.field === 'Action' ? (
                    <Column
                      key={index}
                      width={e.responsive}
                      title={e.title}
                      field={e.field}
                      cell={_actionCell}
                    />
                  ) : e.field === 'No' ? (
                    <Column
                      key={index}
                      width={e.responsive}
                      title={e.title}
                      cell={_rowNumberCell}
                    />
                  ) : e.field === 'Created_Date' ? (
                    <Column
                      key={index}
                      width={e.responsive}
                      title={e.title}
                      field={e.field}
                      cell={_uploadOnCell}
                    />
                  ) : e.field === 'Filename' ? (
                    <Column
                      key={index}
                      width={e.responsive}
                      title={e.title}
                      field={e.field}
                      cell={_fileNameCell}
                    />
                  ) : e.field === 'Filesize' ? (
                    <Column
                      key={index}
                      width={e.responsive}
                      title={e.title}
                      field={e.field}
                      cell={_fileSizeCell}
                    />
                  ) : (
                    <Column
                      key={index}
                      title={e.title}
                      field={e.field}
                      width={e.responsive}
                      cell={_centeredCell}
                    />
                  )
                )}
              </Grid>
            )}
          </SrModalGridContainer>
        )}
      </ModalDocContainer>
      <div id='sr-doc_mdl-anchor_container-div' style={{ display: 'none' }}>
        {attachments.length > 0 &&
          attachments.map((v, i) => (
            <div key={i}>
              <button
                id={`sr-hidden-doc_mdl-download-one-btn-${i + 1}`}
                onClick={() => {
                  _downloadFileFromServerHiddenAnchor({ dataIndex: i, dataItem: v })
                }}
              >
                download
              </button>
              <a
                id={`download-hidden-${i + 1}`}
                download={fileDownload.Filename}
                href={fileDownload.dataBase64}
              >
                data
              </a>
            </div>
          ))}
      </div>
    </div>
  )
}

export default DocumentModalContent
