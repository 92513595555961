/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import _, { isEmpty } from 'lodash'
import * as Yup from 'yup'
import moment from 'moment'
import { isNull } from 'lodash'
import { useFormik } from 'formik'
import getConfig from 'next/config'
import TextField from '@material-ui/core/TextField'
import { useDispatch, useSelector } from 'react-redux'
import CircleIcon from '@material-ui/icons/FiberManualRecord'
import { useState, useEffect, useRef, useImperativeHandle } from 'react'

import { Images } from '../../../assets'
import LogApi from '../../../api/master-data/log'
import { SET_IS_TAB_SR_VALID } from '../../../redux/actions'
import {
  Input,
  InputDateTime,
  InputSelect,
  ButtonDetail,
  Checkbox
} from '../../../components'

import {
  VesselAPI,
  DashboardAPI,
  ExecutionAPI,
  MasterDataAPI,
  LintasGeneralAPI
} from '../../../api'

import {
  Lookup,
  TimeConvertToHour,
  ConvertLocalTimePost,
  ConvertLocalTimePostUTC,
  getDurationBetweenTwoTime,
  ConvertLocalTimeGet
} from '../../../utils'

import {
  Row,
  Form,
  Text,
  TopIcon,
  TextTop1,
  TextTop2,
  TextTop3,
  SVGIcon,
  Container,
  TitleValue,
  ContainerTop,
  WrapperValue,
  TitleBerthing,
  FormContainer,
  LeftFormContainer,
  RightFormContainer,
  BorderLine
} from './../../service_request/service-request.styled'

import ModalCCU from './ccu'
import ModalWorkPermit from './work-permit'
import SRField from './json/column_moal_sr_mapping_field.json'
import { ButtonTabSr, CustomModal } from '../execution.styled'

const ExeSRModalContent = (props) => {
  const dispatch = useDispatch()
  const { detailAllSR } = useSelector((state) => state.serviceRequestAllReducer)
  const [srMapping, setSRMapping] = useState([])
  const [isClicked, setIsClicked] = useState(false)
  const [regularCode, setRegularCode] = useState('')
  const [dataContract, setDataContract] = useState([])
  const [requiredField, setRequiredField] = useState({})
  const [isPostponed, setIsPostponed] = useState(props.dataAll.Flag_Postpone)
  const [inboundData, setInboundData] = useState({
    Status: '',
    Remarks: '',
    Created_Date: ''
  })

  const timerInbound = useRef(null)
  const { publicRuntimeConfig } = getConfig()
  const RUN_OUTSCOPE_FUNCTION =
    publicRuntimeConfig.RUN_OUTSCOPE_FUNCTION === 'true'

  const [required, setRequired] = useState(false)
  const [modalCCU, setModalCCU] = useState(false)
  const [modalPermit, setModalPermit] = useState(false)
  const handleCloseModalCCU = () => setModalCCU(!modalCCU)
  const handleCloseModalPermit = () => setModalPermit(!modalPermit)

  const [dataCCUS, setDataCCUS] = useState([])
  const [dataPermit, setDataWPermit] = useState([])
  const [contractTitle, setContractTitle] = useState([])
  const [dataTotalPermit, setDataTotalPermit] = useState(0)

  const [dataList, setDatalist] = useState({
    dataAgent: [],
    dataJetty: [],
    dataVessel: [],
    dataPLBType: [],
    dataCutting: [],
    dataRecipient: [],
    dataWorkPermit: [],
    dataLocalPoint: [],
    dataVesClearance: []
  })

  useEffect(() => {
    if (
      !isEmpty(props.dataAll.Sr_Type_Code) ||
      !isEmpty(FormType.values['Sr_Type_Code'])
    ) {
      _getSRField(
        props.dataAll.Sr_Type_Code,
        props.dataAll.Sr_Field_Header_Code,
        props.dataAll
      )
    }
  }, [props.dataAll.Sr_No])

  const _getVessel = async () => {
    const filter = '[{"field":"is_active","type":"bool","value":"true"}]'
    const response = await DashboardAPI.GetAllMasterDataVessel(0, 100, filter)
  }

  const _getDataCCUDetail = async (params) => {
    try {
      const response = await ExecutionAPI.GetListCCU(params.Code)
      if (response && response.data.message.data) {
        const result =
          response.data.message.data.length > 0
            ? response.data.message.data.map((e, index) => {
                ;(e['editIndex'] = index),
                  (e['on_hire_date'] = e.on_hire_date
                    ? ConvertLocalTimeGet(
                        params.Location,
                        e.on_hire_date,
                        'YYYY-MM-DD HH:mm'
                      )
                    : '')
                e['off_hire_date'] = e.off_hire_date
                  ? ConvertLocalTimeGet(
                      params.Location,
                      e.off_hire_date,
                      'YYYY-MM-DD HH:mm'
                    )
                  : ''
                e['remarks'] = e.status
                  ? `CCU is booked for ${ConvertLocalTimeGet(
                      params.Location,
                      e.min_date,
                      'DD MMM YYYY HH:mm'
                    )} - ${ConvertLocalTimeGet(
                      params.Location,
                      e.max_date,
                      'DD MMM YYYY HH:mm'
                    )}`
                  : ''
                return e
              })
            : []
        setDataCCUS(result)
      }
    } catch (error) {
      return error
    }
  }

  const _generateCapitalized = (data) => {
    const newCapitalize = []
    data.split('_').map((e) => {
      newCapitalize.push(e.charAt(0).toUpperCase() + e.slice(1))
    })
    return newCapitalize.join('_')
  }

  const _getSRField = async () => {
    try {
      const data = {
        action: 'GET_SR_FIELD',
        data: {
          SR_Type_Code: props.dataAll.Sr_Type_Code,
          SR_Field_Header_Code: props.dataAll.SR_Field_Header_Code
        },
        query: {
          Skip: 0,
          Take: 100,
          orders: [
            {
              field: 'sort',
              dir: 'asc'
            }
          ],
          Filters: [{ field: 'is_active', type: 'bool', value: 'true' }]
        }
      }
      const result = await ExecutionAPI.GetSRFieldMapping(data)
      if (
        result?.message &&
        result?.message?.data &&
        result?.message?.data?.length > 0
      ) {
        const srField = result?.message?.data?.map((dt) => {
          const port = SRField?.filter(
            (sr) => sr?.sr_render.toLowerCase() == dt?.Data_Field.toLowerCase()
          )
          let field = {
            ...dt,
            Data_Field_Port: port.length > 0 ? port[0].sr_field : '',
            Disable: port.length > 0 ? port[0].sr_disable : false
          }
          return field
        })
        setSRMapping(srField?.sort((a, b) => a.Sort - b.Sort))

        let dataField = {}
        srField?.map((e) => {
          Object.keys(e)
            .filter((i) =>
              e['Data_Type'] == 'valuelist'
                ? i == 'Data_Field'
                : i == 'Data_Field_Port'
            )
            .forEach((v) => {
              const dataKey = _generateCapitalized(e[v])

              if (e.Is_Required && e.Data_Type == 'valuelist') {
                dataField[dataKey] = Yup.string().required('*required field')
              } else if (e.Is_Required && e.Data_Type == 'text') {
                dataField[dataKey] = Yup.string().required('*required field')
              } else if (
                e.Is_Required &&
                e.Data_Type == 'calendar' &&
                e.Data_Field_Port !== 'Plan_Depart'
              ) {
                dataField[dataKey] = Yup.date().required('*required field')
              } else if (
                e.Is_Required &&
                e.Data_Type == 'calendar' &&
                e.Data_Field_Port === 'Plan_Depart'
              ) {
                dataField[dataKey] = Yup.date()
                  .required('*required field')
                  .min(
                    Yup.ref('Plan_Arrival'),
                    'Must be greater than Planning Arrival'
                  )
              }
            })
        })

        setRequiredField(dataField)
      }
    } catch (err) {
      return err
    }
  }

  const getRegularCode = async () => {
    try {
      const result = await VesselAPI.GetListVesselCategory()
      const regularGrid = result.data.data.find((v) => v.Value === 'Regular')
      setRegularCode(regularGrid.Code)
    } catch (err) {
      return err
    }
  }

  const Activate = () => {
    setIsPostponed(!isPostponed)
  }
  const updateTabSr = async (data) => {
    try {
      const response = await ExecutionAPI.EditTabSr(data)
      if (response.data && response.data.result) {
        // if (props.getDataExecution) {
        //   props.getDataExecution()
        // }
        props.setAlert({
          msg: 'Update data has been successful',
          success: true,
          visible: true,
          type: 'update'
        })
      } else {
        props.setAlert({
          msg: 'Update data has failed',
          success: false,
          visible: true,
          type: 'update'
        })
      }
      setIsClicked(false)
    } catch (err) {
      setIsClicked(false)
      return err
    }
  }

  const _calculateBerthDurMask = (minutes) => {
    const min = moment.duration(minutes, 'minutes')
    const hh = parseInt(min.asHours())
    const hour = hh ? `${hh} ${hh > 1 ? 'hours' : 'hour'}` : ''
    const mm = parseInt(min.asMinutes()) % 60
    const minute = mm ? `${mm} ${mm > 1 ? 'minutes' : 'minute'}` : ''
    return hour || minute ? `${hour} ${minute}` : '0'
  }

  const _getMasterData = async (params) => {
    try {
      const response = await MasterDataAPI.GetMasterDataPort(
        params?.Location_Code
      )
      const recipient = await MasterDataAPI.GetMasterRecipient()
      const agent = await MasterDataAPI.GetMasterDataAgent()

      if (
        response &&
        !isNull(response.data.message.data) &&
        recipient &&
        !isNull(recipient.data.message.data) &&
        agent &&
        !isNull(agent.data.message.Agent)
      ) {
        const data = response.data.message.data[0]
        setDatalist({
          dataJetty: data.jetty ?? [],
          dataVessel: data.vessel ?? [],
          dataPLBType: data.plb_type ?? [],
          dataLocalPoint: data.local_point ?? [],
          dataWorkPermit: data.work_permit ?? [],
          dataAgent: agent.data.message.Agent ?? [],
          dataCutting: data.cutting_mixing_type ?? [],
          dataVesClearance: data.vessel_clearance ?? [],
          dataRecipient: recipient.data.message.data ?? []
        })
      }
    } catch (error) {
      return error
    }
  }

  const _getContractTitle = async () => {
    try {
      const poList = await MasterDataAPI.GetContractTitle()
      setContractTitle(poList?.data?.message?.POList ?? [])
    } catch (error) {
      console.log('eror', error)
    }
  }

  const _getDataContract = async (params) => {
    try {
      const response = await LintasGeneralAPI.GetDataContract(
        params?.Client_Code
      )
      if (response?.data?.message?.Contracts) {
        setDataContract(response?.data?.message?.Contracts ?? [])
      }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    getRegularCode()
    _getContractTitle()
    _getDataContract(props.dataAll)
    _getDataCCUDetail(props.dataAll)
    _getMasterData(props.dataAll)
    return () => clearTimeout(timerInbound.current)
  }, [])


  const FormSubmit = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...props.dataAll,
      Flag_Postpone: props.dataAll.Flag_Postpone,
      Plan_Arrival_Non_Format: props.dataAll.Plan_Arrival_Non_Format,
      Plan_Depart_Non_Format: props.dataAll.Plan_Depart_Non_Format
    },
    validationSchema: Yup.object({
      ...requiredField,
      Code: Yup.string(),
      Postponed_Remarks: !isPostponed
        ? Yup.string()
        : Yup.string().required('Remarks cannot be empty'),
      Flag_Postpone: Yup.bool()
    }),
    onSubmit: async (values, { setFieldError }) => {
      setIsClicked(true)

      values = {
        ...values,
        Flag_Postpone: isPostponed,
        Modified_Date: ConvertLocalTimePostUTC(new Date()),
        Plan_Arrival: ConvertLocalTimePost(
          props.dataAll.Location,
          values.Plan_Arrival_Non_Format
        ),
        Plan_Depart: ConvertLocalTimePost(
          props.dataAll.Location,
          values.Plan_Depart_Non_Format
        ),
        No_Later_Than: ConvertLocalTimePost(
          props.dataAll.Location,
          values.No_Later_Than == '' ? new Date() : values.No_Later_Than
        ),
        Plan_Berth_Dur: getDurationBetweenTwoTime(
          values.Plan_Arrival,
          values.Plan_Depart
        )
      }

      // If Plb_Goods is false, the group is not required. Else otherwise
      if (values.Plb_Goods) {
        if (!values.Plb_Type_Code) {
          setFieldError('Plb_Type_Code', 'Required')
          return
        }

        if (!values.No_Pen) {
          setFieldError('No_Pen', 'Required')
          return
        }

        if (!values.No_Aju) {
          setFieldError('No_Aju', 'Required')
          return
        }
      }

      checkRequired(values.Work_Permit, dataPermit)
        ? setRequired(true)
        : updateTabSr(values)
    }
  })

  const FormType = useFormik({
    enableReinitialize: true,
    initialValues: {
      sr_type_code: props.dataAll.Sr_Type_Code || '',
      sr_category_code: props.dataAll.Sr_Category_Code || '',
      sr_field_header_code: props.dataAll.Sr_Field_Header_Code || ''
    }
  })

  const checkRequired = (values, data) => {
    return (
      (values && data.length == 0) ||
      (values && data.length != 0 && data.findIndex((e) => e.is_active) == -1)
    )
  }

  const dispatchIsTabSrValid = () => {
    dispatch({
      type: SET_IS_TAB_SR_VALID,
      payload: FormSubmit.isValid
    })
  }

  // We need this useEffect because in activity tab, before the status changed into In Progress
  // User needs to provide the correct data, which means fill all the required fields!
  useEffect(() => {
    return () => {
      dispatchIsTabSrValid()
    }
  }, [])

  const insertLog = async () => {
    const dataInsert = {
      Timestamp: new Date(),
      Action: Lookup.LOG_ACTION.UPDATE,
      Module: Lookup.LOG_MODULE.EXECUTION,
      Submodule: Lookup.LOG_SUB_MODULE.SERVICE_REQUEST,
      Detail: 'Remarks Cannot be Empty',
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From: '',
      To: '',
      Refno: props.dataSrCode
    }

    if (
      Object.entries(FormSubmit.errors).length > 0 ||
      Object.entries(FormSubmit.touched).length === 0
    ) {
      const { data } = await LogApi.PostDataLog(dataInsert)
      if (data && data.result) return data.result
    } else return false
  }

  const postponedField = () => {
    return props.dataAll.Postponed_By ? (
      <TextField
        id='exe-sr_mdl-postpone-status-txt'
        style={{ display: 'block', paddingBottom: 20 }}
        error={false}
        helperText={null}
        multiline={props.dataAll.Postponed_By.length > 23}
        rows={2}
        InputProps={{
          readOnly: true,
          style: { display: 'block' }
        }}
        value={`Postponed by ${
          props.dataAll.Postponed_By
        } on ${ConvertLocalTimeGet(
          props.dataAll.Location,
          props.dataAll.Postponed_Date,
          'DD/MM/YYYY HH:mm'
        )}`}
      />
    ) : null
  }

  const reactivatedField = () => {
    return props.dataAll.Acivated_By ? (
      <TextField
        id='exe-sr_mdl-reactivated-status-txt'
        style={{ display: 'block', paddingBottom: 20 }}
        helperText={null}
        multiline={props.dataAll.Acivated_By.length > 23}
        rows={2}
        InputProps={{
          readOnly: true,
          style: { display: 'block' }
        }}
        value={`Reactivated by ${
          props.dataAll.Acivated_By
        } on ${ConvertLocalTimeGet(
          props.dataAll.Location,
          props.dataAll.Acivated_Date,
          'DD/MM/YYYY HH:mm'
        )}`}
      />
    ) : null
  }

  const _formattingDate = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm')
  }

  const _checkValueListType = (e) => {
    let options, optionText, keys

    if (e.Data_Field === 'cutting_mixing_type_code') {
      ;(options = dataList.dataCutting), (optionText = 'value'), (keys = 'code')
    } else if (e.Data_Field === 'recipient_code') {
      ;(options = dataList.dataRecipient),
        (optionText = 'name'),
        (keys = 'code')
    } else if (e.Data_Field === 'agent_code') {
      ;(options = dataList.dataAgent),
        (optionText = 'Agent_Name'),
        (keys = 'Code')
    } else if (e.Data_Field === 'jetty_code') {
      ;(options = dataList.dataJetty),
        (optionText = 'jetty_name'),
        (keys = 'code')
    } else if (e.Data_Field === 'vessel_clearance_code') {
      ;(options = dataList.dataVesClearance),
        (optionText = 'value'),
        (keys = 'code')
    } else if (e.Data_Field === 'vessel_code') {
      ;(options = dataList.dataVessel),
        (optionText = 'vessel_name'),
        (keys = 'code')
    } else if (e.Data_Field === 'plb_type_code') {
      ;(options = dataList.dataPLBType),
        (optionText = 'description'),
        (keys = 'code')
    } else if (e.Data_Field === 'origin_code') {
      ;(options = dataList.dataLocalPoint),
        (optionText = 'description'),
        (keys = 'code')
    } else if (e.Data_Field === 'destination_code') {
      ;(options = dataList.dataLocalPoint),
        (optionText = 'description'),
        (keys = 'code')
    }
    return { options: options, optionText: optionText, keys: keys }
  }

  const _checkCurrentStatus = (data) => {
    let status
    data && !_.isUndefined(data.Sr_Status)
      ? data.Sr_Status === 'In Progress' ||
        data.Sr_Status === 'Completed' ||
        data.Sr_Status === 'Finalized' ||
        data.Sr_Status === 'Waiting for Confirmation'
        ? (status = false)
        : (status = true)
      : (status = false)
    return status
  }

  useImperativeHandle(
    props.cref,
    () => ({
      _updateServiceRequest() {
        FormSubmit.handleSubmit()
      }
    }),
    []
  )

  const _TopForm = (left) => {
    return (
      <Form padding='0px 30px 0px 30px'>
        {left && (
          <Input
            id='exe-sr_mdl-sr_type-select'
            name='Sr_Type'
            label='Service Request Type'
            InputProps={{ readOnly: true }}
            value={props.dataAll.Sr_Type}
          />
        )}
        {!left && (
          <Input
            id='exe-sr_mdl-sr-category-txt'
            name='Sr_Category'
            label='Service Request Category'
            InputProps={{ readOnly: true }}
            value={props.dataAll.Sr_Category}
          />
        )}
      </Form>
    )
  }

  const _ContractNumberForm = () => {
    let convertMasterData = ''
    let filterMasterData = []
    if (dataContract && dataContract?.length > 0) {
      filterMasterData = dataContract?.filter(
        (contract) =>
          contract?.AgreementNo == props?.dataAll?.Agreement_No &&
          contract?.SapContractNo == props?.dataAll?.Contract_No
      )
      if (filterMasterData && filterMasterData?.length > 0) {
        convertMasterData = filterMasterData[0]?.SAP_agrement_no
      }
    }
    return (
      <Form padding='0px 30px 0px 30px'>
        <Input
          id='exe-sr_mdl-contract_no-txt'
          name='Contract_No'
          label='Contract Number'
          InputProps={{ readOnly: true }}
          value={convertMasterData}
        />
      </Form>
    )
  }

  const _ContractTitleForm = () => {
    let convertMasterData = ''
    let filterMasterData = []
    if (dataContract && dataContract?.length > 0) {
      filterMasterData = dataContract?.filter(
        (contract) =>
          contract?.AgreementNo == props?.dataAll?.Agreement_No &&
          contract?.SapContractNo == props?.dataAll?.Contract_No
      )
      if (filterMasterData && filterMasterData?.length > 0) {
        convertMasterData = filterMasterData[0]?.ContractTitle
      }
    }
    return (
      <Form padding='0px 30px 0px 30px'>
        <Input
          id='exe-sr_mdl-contract_title-txt'
          name='contract_title'
          label='Contract Title'
          InputProps={{ readOnly: true }}
          value={convertMasterData}
        />
      </Form>
    )
  }

  const _POListForm = () => {
    let convertMasterData = ''
    let filterMasterData = []
    if (contractTitle && contractTitle?.length > 0) {
      filterMasterData = contractTitle?.filter(
        (poList) => poList?.code == props?.dataAll?.Contract_Title
      )
      if (filterMasterData && filterMasterData?.length > 0) {
        convertMasterData = filterMasterData[0]?.po_no
      }
    }
    return (
      <Form padding='0px 30px 0px 30px'>
        <Input
          id='exe-sr_mdl-po_list-txt'
          name='po_list'
          label='PO List'
          InputProps={{ readOnly: true }}
          value={convertMasterData}
        />
      </Form>
    )
  }

  const _BottomForm = (left) => {
    const styles = {
      helperText: {
        fontSize: '10px',
        color: '#777777',
        marginTop: '2px',
        marginBottom: '5px'
      },
      errorText: {
        color: 'red',
        fontSize: '10px',
        marginTop: '2px',
        marginBottom: '5px'
      },
      addressText: {
        top: -19,
        fontSize: '12px',
        color: '#777777',
        position: 'relative'
      }
    }

    let recipient =
      dataList?.dataRecipient?.length > 0
        ? dataList?.dataRecipient?.filter(
            (e) => e?.code === FormSubmit?.values['Recipient_Code']
          )?.length > 0
          ? dataList?.dataRecipient?.filter(
              (e) => e?.code === FormSubmit?.values['Recipient_Code']
            )[0]?.address
          : ''
        : ''
    recipient =
      recipient.length > 60 ? recipient.substring(0, 60) + '...' : recipient
    return (
      <Form padding='0px 30px 0px 30px'>
        {srMapping?.length > 0 &&
          srMapping
            ?.filter((sr) => {
              if (
                FormSubmit.values.Plb_Goods === false &&
                (sr?.Data_Field === 'plb_type_code' ||
                  sr?.Data_Field === 'no_aju' ||
                  sr?.Data_Field === 'no_pen')
              ) {
                return false
              }
              return true
            })
            .filter((sr, i) => (left ? i % 2 == 0 : i % 2 != 0))
            .map((dt, i) => {
              if (
                dt?.Data_Field === 'planning_start_time' ||
                dt?.Data_Field === 'planning_finish_time'
              ) {
                return _checkCurrentStatus(props.dataAll) ? (
                  <InputDateTime
                    disablePast
                    form={FormSubmit}
                    tabIndex={dt.Sort}
                    label={dt.Data_Desc}
                    title={dt.Data_Desc}
                    className={'height-45'}
                    name={dt.Data_Field_Port}
                    placeholder='(dd/mm/yyyy hh/mm)'
                    id={`exe-sr-mdl-${dt.Data_Field_Port}-date`}
                  />
                ) : (
                  <Input
                    label={dt.Data_Desc}
                    name={dt.Data_Field_Port}
                    InputProps={{ readOnly: true }}
                    value={
                      props.dataAll[dt.Data_Field_Port] == ''
                        ? ''
                        : props.dataAll[dt.Data_Field_Port]
                    }
                    id={`exe-sr-mdl-${dt.Data_Field_Port}-date`}
                  />
                )
              } else if (dt?.Data_Field === 'warehouse_name') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={FormSubmit.values['Warehouse_Name']}
                  />
                )
              } else if (dt?.Data_Field === 'document_type') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={FormSubmit.values['Document_Type']}
                  />
                )
              } else if (dt?.Data_Field === 'truck_no') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={FormSubmit.values['Truck_No']}
                  />
                )
              } else if (dt?.Data_Field === 'room') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={FormSubmit.values['Room']}
                  />
                )
              } else if (dt?.Data_Field === 'rent_area') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={FormSubmit.values['Rent_Area']}
                  />
                )
              } else if (dt?.Data_Field === 'waste_type') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={FormSubmit.values['Waste_Type']}
                  />
                )
              } else if (dt?.Data_Field === 'waste_characteristic') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={FormSubmit.values['Waste_Characteristic']}
                  />
                )
              } else if (dt?.Data_Field === 'location') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={FormSubmit.values['Location']}
                  />
                )
              } else if (dt?.Data_Field === 'vehicle_type') {
                return (
                  <Input
                    id={`exe-sr-mdl-${dt.Data_Field}-txt`}
                    label={dt.Data_Desc}
                    name={dt.Data_Field}
                    InputProps={{ readOnly: true }}
                    value={FormSubmit.values['Vehicle_Type']}
                  />
                )
              } else if (dt.Data_Type.toLowerCase() == 'calendar') {
                return _checkCurrentStatus(props.dataAll) ? (
                  <InputDateTime
                    disablePast
                    form={FormSubmit}
                    tabIndex={dt.Sort}
                    label={dt.Data_Desc}
                    title={dt.Data_Desc}
                    className={'height-45'}
                    name={dt.Data_Field_Port}
                    placeholder='(dd/mm/yyyy hh/mm)'
                    id={`exe-sr-mdl-${dt.Data_Field_Port}-date`}
                  />
                ) : (
                  <Input
                    label={dt.Data_Desc}
                    name={dt.Data_Field_Port}
                    InputProps={{ readOnly: true }}
                    value={
                      props.dataAll[dt.Data_Field_Port] == ''
                        ? ''
                        : ConvertLocalTimeGet(
                            props.dataAll.Location,
                            props.dataAll[dt.Data_Field_Port],
                            'DD/MM/YYYY HH:mm'
                          )
                    }
                    id={`exe-sr-mdl-${dt.Data_Field_Port}-date`}
                  />
                )
              } else if (dt.Data_Type.toLowerCase() == 'valuelist') {
                return _checkCurrentStatus(props.dataAll) ? (
                  <>
                    <InputSelect
                      form={FormSubmit}
                      label={dt.Data_Desc}
                      style={{ width: '100%' }}
                      name={_generateCapitalized(dt.Data_Field)}
                      options={_checkValueListType(dt).options}
                      optionValueKey={_checkValueListType(dt).keys}
                      optionText={_checkValueListType(dt).optionText}
                      id={`exe-sr-mdl-${dt.Data_Field_Port}-list`}
                    />
                    {dt.Data_Field === 'recipient_code' && (
                      <div style={styles.addressText}>{recipient}</div>
                    )}
                  </>
                ) : (
                  <>
                    <InputSelect
                      readOnly={true}
                      form={FormSubmit}
                      label={dt.Data_Desc}
                      style={{ width: '100%' }}
                      name={_generateCapitalized(dt.Data_Field)}
                      options={_checkValueListType(dt).options}
                      optionValueKey={_checkValueListType(dt).keys}
                      optionText={_checkValueListType(dt).optionText}
                      id={`exe-sr-mdl-${dt.Data_Field_Port}-list`}
                    />
                    {dt.Data_Field === 'recipient_code' && (
                      <div style={styles.addressText}>{recipient}</div>
                    )}
                  </>
                )
              } else if (
                dt.Data_Type.toLowerCase() == 'text' &&
                dt.Data_Field_Port == 'Plan_Berth_Dur'
              ) {
                return (
                  <Input
                    form={FormSubmit}
                    label={dt.Data_Desc}
                    name={dt.Data_Field_Port}
                    InputProps={{ readOnly: true }}
                    value={TimeConvertToHour(
                      props.fromScheduler
                        ? FormSubmit.values['Plan_Arrival_Non_Format']
                        : FormSubmit.values['Plan_Arrival'],
                      props.fromScheduler
                        ? FormSubmit.values['Plan_Depart_Non_Format']
                        : FormSubmit.values['Plan_Depart']
                    )}
                    id={`exe-sr-mdl-${dt.Data_Field_Port}-text`}
                  />
                )
              } else if (
                dt.Data_Type.toLowerCase() == 'text' &&
                dt.Data_Field_Port != 'Plan_Berth_Dur'
              ) {
                return (
                  <Input
                    form={FormSubmit}
                    label={dt.Data_Desc}
                    name={dt.Data_Field_Port}
                    id={`exe-sr-mdl-${dt.Data_Field_Port}-text`}
                    type={dt.Data_Field == 'jetty_position' ? 'number' : 'text'}
                    InputProps={{
                      readOnly: !_checkCurrentStatus(props.dataAll)
                    }}
                  />
                )
              } else if (dt.Data_Field === 'jetty_position') {
                return (
                  <Input
                    form={FormSubmit}
                    label={dt.Data_Desc}
                    name={dt.Data_Field_Port}
                    id={`exe-sr-mdl-${dt.Data_Field_Port}-text`}
                    type='number'
                    InputProps={{
                      readOnly: !_checkCurrentStatus(props.dataAll)
                    }}
                  />
                )
              } else if (dt.Data_Type === 'boolean') {
                return (
                  <div style={{ height: '67px' }} key={i}>
                    <Checkbox
                      readOnly={!_checkCurrentStatus(props.dataAll)}
                      form={FormSubmit}
                      name={dt.Data_Field_Port}
                      title={dt.Data_Desc}
                    />
                  </div>
                )
              } else if (dt.Data_Type === 'form_detail') {
                return (
                  <div style={{ height: '67px' }} key={i}>
                    <ButtonDetail
                      title={dt.Data_Desc}
                      isCCU={true}
                      id={`exe-sr-mdl-${dt.Data_Field}-btn`}
                      onClick={
                        dt.Data_Field === 'ccu_detail'
                          ? () => {
                              setModalCCU(true)
                            }
                          : dt.Data_Field === 'work_permit_detail'
                          ? () => {
                              setModalPermit(true)
                            }
                          : null
                      }
                    >
                      {dt.Data_Desc}
                    </ButtonDetail>
                    {dt.Data_Field === 'ccu_detail' && (
                      <div style={styles.helperText}>
                        {dataCCUS && dataCCUS.length !== 0
                          ? `You have added ${dataCCUS.length} CCU`
                          : 'You have not added CCU'}
                      </div>
                    )}
                    {dt.Data_Field === 'work_permit_detail' &&
                      (checkRequired(
                        FormSubmit.values['Work_Permit'],
                        dataPermit
                      ) && required ? (
                        <div style={styles.errorText}>{'*required field'}</div>
                      ) : (
                        <div style={styles.helperText}>
                          {dataTotalPermit && dataTotalPermit !== 0
                            ? `You have added ${dataTotalPermit} Work Permit`
                            : 'You have not added Work Permit'}
                        </div>
                      ))}
                    {}
                  </div>
                )
              }
            })}
      </Form>
    )
  }

  const _AdditionalForm = (left) => {
    return (
      <Form padding='0px 30px 0px 30px'>
        {left && (
          <Input
            id='exe-sr_mdl-location-txt'
            name='Location'
            label='Location'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={detailAllSR?.Location}
          />
        )}
        {!left && (
          <Input
            id='exe-sr_mdl-truck-no-txt'
            name='Truck_No'
            label='Truck No'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={detailAllSR?.Truck_No}
          />
        )}
        {left && (
          <Input
            id='exe-sr_mdl-rent-area-txt'
            name='Rent_Area'
            label='Rent Area'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={detailAllSR?.Rent_Area}
          />
        )}
        {!left && (
          <Input
            id='exe-sr_mdl-room-txt'
            name='Room'
            label='Room'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={detailAllSR?.Room}
          />
        )}
        {left && (
          <Input
            id='exe-sr_mdl-warehouse-name-txt'
            name='Warehouse_Name'
            label='Warehouse Name'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={detailAllSR?.Warehouse_Name}
          />
        )}
        {!left && (
          <Input
            id='exe-sr_mdl-vehicle-type-txt'
            name='Vehicle_Type'
            label='Vehicle Type'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={detailAllSR?.Vehicle_Type}
          />
        )}
        {left && (
          <Input
            id='exe-sr_mdl-waste-characteristic-txt'
            name='Waste_Characteristic'
            label='Waste Characteristic'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={detailAllSR?.Waste_Characteristic}
          />
        )}
        {!left && (
          <Input
            id='exe-sr_mdl-waste-type-txt'
            name='Waste_Type'
            label='Waste Type'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={detailAllSR?.Waste_Type}
          />
        )}
        {left && (
          <Input
            id='exe-sr_document-type-txt'
            name='Document_Type'
            label='Document Type'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={detailAllSR?.Document_Type}
          />
        )}
      </Form>
    )
  }

  return (
    <FormContainer style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0 }}>
      <LeftFormContainer>
        <Container>
          <Row>
            {_TopForm(true)}
            {_TopForm(false)}
          </Row>
          <Row>
            {_ContractNumberForm()}
            {_ContractTitleForm()}
          </Row>
          <div>
            {_POListForm()}
            <BorderLine />
          </div>
          <Row>
            {_BottomForm(true)}
            {_BottomForm(false)}
          </Row>
          <Row>
            {/* {_AdditionalForm(true)} */}
            {/* {_AdditionalForm(false)} */}
          </Row>
        </Container>
      </LeftFormContainer>
      <RightFormContainer className='scrollable'>
        <Form className='scrollable'>
          <>
            <ContainerTop align='baseline'>
              <TextTop1>Created By</TextTop1>
              <TextTop2>
                {props.dataAll?.Created_By?.split('(')[0] || ''}
              </TextTop2>
            </ContainerTop>
            <ContainerTop align='center'>
              <TopIcon src={Images.company} />
              <TextTop3>
                {!_.isUndefined(props.dataAll?.Created_By?.split('(')[1])
                  ? props.dataAll.Created_By.split('(')[1].split(')')[0]
                  : '-'}
              </TextTop3>
              <TopIcon src={Images.time} />
              <TextTop3>{_formattingDate(props.dataAll.Created_Date)}</TextTop3>
            </ContainerTop>
            <WrapperValue>
              <TitleValue>Estimation Date</TitleValue>
              <SVGIcon src={Images.schedule} />
            </WrapperValue>
            <WrapperValue>
              <TitleBerthing>
                {_formattingDate(props.dataAll.Eta)}
              </TitleBerthing>
              <Text regular={true}>to</Text>
              <TitleBerthing>
                {_formattingDate(props.dataAll.Etd)}
              </TitleBerthing>
            </WrapperValue>
            <WrapperValue>
              <Input
                id='exc-tab-sr-ata'
                name='Actual_Time_Arrival'
                label='Actual Time Arrival'
                InputProps={{ readOnly: true }}
                disabled={true}
                value={props.dataAll.Location}
              />
            </WrapperValue>
          </>
          <Input
            id='sr-sr_mdl-remarks-txt'
            multiline
            maxRows={3}
            label='Request Description'
            variant='outlined'
            name='Remarks'
            InputProps={{ readOnly: true }}
            value={props.dataAll.Remarks}
            inputProps={{
              tabIndex: 11
            }}
          />
          <Input
            id='sr-sr_mdl-client_name-txt'
            name='Client_Name'
            label='Client Name'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={props.dataAll.Client}
          />
          <Input
            id='sr-sr_mdl-location-txt'
            name='Location'
            label='Location'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={props.dataAll.Location}
          />
          <Input
            id='sr-sr_mdl-sr_number-txt'
            name='SR_Number'
            label='SR Number'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={props.dataAll.Sr_No}
          />
          <Input
            id='sr-sr_mdl-priority-txt'
            name='Remarks'
            label='Priority Request'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={props.dataAll.Priority_Desc}
          />

          {isPostponed ? (
            <ButtonTabSr
              style={{
                background:
                  props.dataAll.Sr_Status !== 'In Progress' &&
                  props.dataAll.Sr_Status !== 'Completed'
                    ? '#E30000'
                    : '#A9A9A9'
              }}
              onClick={() => {
                if (
                  !props.isDetail &&
                  props.dataAll.Sr_Status !== 'In Progress' &&
                  props.dataAll.Sr_Status !== 'Completed'
                ) {
                  Activate()
                }
              }}
            >
              <div style={{ marginTop: '3px' }}>
                <CircleIcon htmlColor='white' fontSize='large' />
              </div>
              <div style={{ color: 'white', flex: 1 }}>Postpone</div>
            </ButtonTabSr>
          ) : (
            <ButtonTabSr
              style={{
                background:
                  props.dataAll.Sr_Status !== 'In Progress' &&
                  props.dataAll.Sr_Status !== 'Completed'
                    ? '#00C34B'
                    : '#A9A9A9'
              }}
              onClick={() => {
                if (
                  !props.isDetail &&
                  props.dataAll.Sr_Status !== 'In Progress' &&
                  props.dataAll.Sr_Status !== 'Completed'
                ) {
                  Activate()
                }
              }}
            >
              <div style={{ color: 'white', flex: 1 }}>Activate</div>
              <div style={{ marginTop: '3px' }}>
                <CircleIcon htmlColor='white' fontSize='large' />
              </div>
            </ButtonTabSr>
          )}

          <Input
            id='exe-sr_mdl-remarks-txt'
            type='text'
            label='Postponed Remarks'
            multiline
            maxRows={3}
            InputProps={{
              readOnly: !isPostponed
            }}
            variant='outlined'
            name='Postponed_Remarks'
            form={FormSubmit}
          />
          {(props.dataAll.Postponed_By || props.dataAll.Acivated_By) && (
            <label
              style={{
                display: 'flex',
                marginTop: 0,
                marginBottom: 5,
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
                color: 'rgba(0,0,0,0.5)'
              }}
            >
              Detail Activity
            </label>
          )}
          {moment(props.dataAll.Postponed_Date) <
          moment(props.dataAll.Acivated_Date) ? (
            <>
              {postponedField()}
              {reactivatedField()}
            </>
          ) : (
            <>
              {reactivatedField()}
              {postponedField()}
            </>
          )}
        </Form>
      </RightFormContainer>
      {/* <ModalPermit
        dataSr={props.data}
        modalPermitCCU={modalPermit}
        handleClose={handleCloseModalPermit}
        data={[]}
        title='Work Permit'
      /> */}
      <ModalCCU
        data={dataCCUS}
        title='CCU Detail'
        showModal={modalCCU}
        dataAll={props.dataAll}
        handleClose={handleCloseModalCCU}
        getData={() => _getDataCCUDetail(props.dataAll)}
        readOnly={!_checkCurrentStatus(props.dataAll)}
      />
      <ModalWorkPermit
        title='Work Permit Detail'
        showModal={modalPermit}
        dataAll={props.dataAll}
        data={dataList.dataWorkPermit}
        handleClose={handleCloseModalPermit}
        setData={(e) => setDataWPermit(e)}
        readOnly={!_checkCurrentStatus(props.dataAll)}
        setDataTotalPermit={setDataTotalPermit}
      />
    </FormContainer>
  )
}
export default ExeSRModalContent
