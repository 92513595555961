/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'

import { useSelector } from 'react-redux'
import { ServiceRequestAPI } from '../../api'
import { setToBase64, prefix, ConvertLocalTimeGet } from '../../utils'
import { CloudIcon, XlsxIcon, DocxIcon, PdfIcon } from '../../components'

import { Images } from '../../assets'
import {
  TextLink,
  DownloadButton,
  ActionContainer,
  TotalPackageText,
  DownloadAllButton,
  SrModalGridContainer,
  DocumentDownloadAllButtonContainer,
} from './service-request-detail.styled'
import DocumentGridColumn from './column.modal_doc.json'
import { setToBase64SR } from '../../utils/prefixBase64'

const DocModalContent = ({ dataSr, attachments, setAttachments }) => {
  const [fileSize, setFileSize] = useState(20)
  const [fileType, setFileType] = useState('')
  const [attachmentTypeCode, setAttachmentTypeCode] = useState('')
  const { usersProfileReducer } = useSelector((state) => state)
  const [fileDownload, setFileDownload] = useState({ filename: '', dataBase64: '' })

  const documentGridHeight = '61.5vh' /*window.innerHeight - 575 > 200 ? window.innerHeight - 575 : 200 */
  const _getAttachmentTypeCode = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"Attachment_Type"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllFromTableEnum(0, 100, filter)
      data.forEach((v) => {
        if (v.Value.toLowerCase() === 'sr attachment') {
          setAttachmentTypeCode(v.Code)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const _getFileSize = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"File_Size"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllFromTableEnum(0, 100, filter)
      setFileSize(parseInt(data[0].Value))
    } catch (error) {
      console.log(error)
    }
  }

  const _getFileType = async () => {
    try {
      let parsedData = []
      const filterDocument =
        '[{"field":"type","type":"text","value":"File_Document"},{"field":"is_active","type":"bool","value":"true"}]'
      const documentEnum = await ServiceRequestAPI.GetAllFromTableEnum(0, 100, filterDocument)
      if (documentEnum.data.length) {
        parsedData.push(documentEnum.data[0].Value)
      }
      const filterImage =
        '[{"field":"type","type":"text","value":"File_Image"},{"field":"is_active","type":"bool","value":"true"}]'
      const imageEnum = await ServiceRequestAPI.GetAllFromTableEnum(0, 100, filterImage)
      if (imageEnum.data.length) {
        parsedData.push(imageEnum.data[0].Value)
      }
      parsedData = parsedData.join('|')
      parsedData = parsedData.split('|').join(', .')
      setFileType('.' + parsedData)
    } catch (error) {
      console.log(error)
    }
  }

  const _getAllAttachment = async () => {
    try {
      const filter = `[{"field":"sr_code","type":"text","value":"${dataSr.Code}"}]`
      const { data } = await ServiceRequestAPI.GetAllAttachment(0, 1000, filter)
      let filteredData = data ? data.filter((v) => v.Sr_Code === dataSr.Code) : []
      filteredData = _.orderBy(filteredData, ['Created_Date'], ['desc'])

      let filteredDataAttachment = filteredData.map((e)=>{
        let base64 = null
        if(e.Path.toLowerCase().includes('lbs-attachment')) {
          base64 = setToBase64SR(e.Filename_Original)
        } else {
          base64 = setToBase64(e.Filename_Original)
        }
        return{
          ...e,
          ['Filename_Base64']:base64
        }
      })

      // for (const data of filteredData) {
      //   filteredDataAttachment.push({
      //     ...data,
      //     ['Filename_Base64']: await setToBase64(data.Filename),
      //   })
      // }

      setAttachments(filteredDataAttachment)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getAll()
  }, [])

  const _getAll = async () => {
    await _getFileSize()
    await _getFileType()
    await _getAllAttachment()
    await _getAttachmentTypeCode()
  }
  const _downloadAll = () => {
    for (let i = 0; i < attachments.length; i++) {
      document.getElementById(`sr-hidden-doc_mdl-download-one-btn-${i + 1}`).click()
    }
  }
  const _rowNumberCell = (props) => (
    <td>
      <p
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.dataIndex + 1}
      </p>
    </td>
  )
  const _centeredCell = (props) => {
    return <td style={{ textAlign: 'center' }}>{props.dataItem[props.field]}</td>
  }

  const _uploadOnCell = (props) => (
    <td>{ConvertLocalTimeGet(dataSr.Location, props.dataItem.Created_Date, 'DD/MM/YYYY HH:mm')}
    </td>

  )
  const _fileNameCell = (props) => (
    <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 75 }}>
      <span style={{ marginRight: 10, fontSize: 30 }}>{_getFileIcon(props)}</span>
      {moment(props.dataItem.Created_Date) < moment('2021-03-01 20:00Z') ? (
        <TextLink
          onClick={() => {
            if (
              props.dataItem.Filename.includes('.doc') ||
              props.dataItem.Filename.includes('.xls')
            ) {
              window.open(props.dataItem.Path)
            } else {
              let win = window.open()

              win.document.write(`<iframe
          style="
          width:100%;
          height:100%;
          "
          src="${props.dataItem.Filename_Base64}" />`)
            }
          }}
          // style={{ color: 'blue', textDecoration: 'underline', width: '250px', cursor: 'pointer' }}
        >
          {props.dataItem.Filename}
        </TextLink>
      ) : (
        <a href={props.dataItem.Path} target='_blank' rel='noopener noreferrer'>
          {props.dataItem.Filename}
        </a>
      )}
    </td>
  )
  const _fileSizeCell = (props) => <td>{parseInt(+props.dataItem.Filesize / 1000) + ' kb'}</td>

  const _getFileIcon = (props) => {
    const filename = props.dataItem.Filename
    const extension = filename.split('.')[filename.split('.').length - 1].toLowerCase()

    if (extension === 'doc' || extension === 'docx') {
      return <DocxIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'xls' || extension === 'xlsx') {
      return <XlsxIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'pdf') {
      return <PdfIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
      return <img src={Images.filesIconImg} dataIndex={props.dataIndex} />
    }
  }
  const _downloadFileFromServerHiddenAnchor = async (props) => {
    console.log('_downloadFileFromServerHiddenAnchor', props  )
    try {
      let response = null
      if(props.dataItem.Path.toLowerCase().includes('lbs-attachment')) {
        response = await ServiceRequestAPI.GetAttachmentFileSR(props.dataItem.Filename)
      } else {
        response = await ServiceRequestAPI.GetAttachmentFile(props.dataItem.Filename)
      }
      const baseFile64 = response.data
      const prefixBase64 = prefix(props.dataItem.Filename)
      const dataBase64 = prefixBase64 + baseFile64
      setFileDownload({ Filename: props.dataItem.Filename, dataBase64: dataBase64 })
      document.getElementById(`download-hidden-${props.dataIndex + 1}`).click()
    } catch (err) {
      console.log(err)
    }
  }

  const _downloadFileFromServer = async (props) => {
    console.log('_downloadFileFromServer', props)
    try {
      let response = null
      if(props.dataItem.Path.toLowerCase().includes('lbs-attachment')) {
        response = await ServiceRequestAPI.GetAttachmentFileSR(props.dataItem.Filename)
      } else {
        response = await ServiceRequestAPI.GetAttachmentFile(props.dataItem.Filename)
      }
      const baseFile64 = response.data
      const prefixBase64 = prefix(props.dataItem.Filename)
      const dataBase64 = prefixBase64 + baseFile64
      setFileDownload({ Filename: props.dataItem.Filename, dataBase64: dataBase64 })
      document.getElementById(`download-${props.dataIndex + 1}`).click()
    } catch (err) {
      console.log(err)
    }
  }

  const _actionCell = (props) => (
    <td>
      <ActionContainer>
        <DownloadButton
          id={`sr-doc_mdl-download-one-btn-${props.dataIndex + 1}`}
          onClick={() => {
            _downloadFileFromServer(props)
          }}
          style={{ cursor: 'pointer' }}
        >
          <CloudIcon style={{ marginRight: 6.4 }} />
          Download
        </DownloadButton>
        <a
          href={fileDownload.dataBase64}
          download={fileDownload.Filename}
          id={`download-${props.dataIndex + 1}`}
          style={{ display: 'none' }}
        >
          download
        </a>
      </ActionContainer>
    </td>
  )
  return (
    <div>
      {attachments.length > 0 ? (
        <DocumentDownloadAllButtonContainer>
          <DownloadAllButton id='sr-doc_mdl-download-all-btn' onClick={_downloadAll}>
            <CloudIcon style={{ marginRight: 6.4 }} />
            Download All
          </DownloadAllButton>
        </DocumentDownloadAllButtonContainer>
      ) : null}
      <SrModalGridContainer>
        <div
          style={{
            height: 35,
            display: 'flex',
            paddingRight: 5,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <TotalPackageText>
            {`Total Document: ${[...attachments].filter((e) => !e.Deleted_By).length}`}
          </TotalPackageText>
        </div>
        <Grid
          id='sr-doc_mdl-all-data'
          data={[...attachments].filter((e) => !e.Deleted_By)}
          total={attachments.length}
          style={{ height: documentGridHeight }}
          rowHeight={20}
          pageSize={8}
        >
          {DocumentGridColumn.map((e, index) =>
            e.field === 'Action' ? (
              <Column
                key={index}
                width={e.width}
                title={e.title}
                field={e.field}
                cell={_actionCell}
              />
            ) : e.field === 'No' ? (
              <Column
                key={index}
                width={e.width}
                title={e.title}
                cell={_rowNumberCell}
              />
            ) : e.field === 'Created_Date' ? (
              <Column
                key={index}
                width={e.width}
                title={e.title}
                field={e.field}
                cell={_uploadOnCell}
              />
            ) : e.field === 'Filename' ? (
              <Column
                key={index}
                width={e.width}
                title={e.title}
                field={e.field}
                cell={_fileNameCell}
              />
            ) : e.field === 'Filesize' ? (
              <Column
                key={index}
                width={e.width}
                title={e.title}
                field={e.field}
                cell={_fileSizeCell}
              />
            ) : (
              <Column
                key={index}
                title={e.title}
                field={e.field}
                width={e.width}
                cell={_centeredCell}
              />
            )
          )}
        </Grid>
      </SrModalGridContainer>
      <div id='sr-doc_mdl-anchor_container-div' style={{ display: 'none' }}>
        {attachments.length > 0 &&
          attachments.map((v, i) => (
            <div key={i}>
              <button
                id={`sr-hidden-doc_mdl-download-one-btn-${i + 1}`}
                onClick={() => {
                  _downloadFileFromServerHiddenAnchor({ dataIndex: i, dataItem: v })
                }}
              >
                download
              </button>
              <a
                id={`download-hidden-${i + 1}`}
                download={fileDownload.Filename}
                href={fileDownload.dataBase64}
              >
                data
              </a>
            </div>
          ))}
      </div>
    </div>
  )
}

export default DocModalContent
